// Import mixins
@import 'assets/styles/mixins.scss';

/////////////////////////////////////////////////////////////////////////////////////////
/*  ANT DESIGN INPUT */

.ant-input-disabled {
  background: $gray-1;
}
.ant-checkbox-wrapper {
  color: rgba(0, 0, 0, 0.65);
}

input[type='text'],
input[type='password'],
input[type='number'],
textarea,
.ant-input,
.ant-input-number-input {
  background: #ffffff;
  border: 1px solid #d6dce5;
  box-sizing: border-box;
  box-shadow: inset 0px 1px 2px #e0e0e0;
  border-radius: 6px;
  height: 40px;
  padding: 10px 15px;
  font-size: 12px;
  color: #4d5a74;
  font-weight: 500;
  letter-spacing: 0.1px;
}
.ant-input-number {
  height: auto !important;
  border: 1px solid transparent;
  border-radius: 5px;
  width: 100% !important;
  &:hover {
    border: 1px solid $primary;
  }
}
.ant-calendar-picker:hover .ant-calendar-picker-input:not(.ant-input-disabled) {
  border-color: $primary;
}
.ant-input:focus,
.ant-input:hover {
  border-color: $primary;
  border-right-width: 1px !important;
  outline: 0;
  -webkit-box-shadow: inset 0px 1px 2px #e0e0e0;
  box-shadow: inset 0px 1px 2px #e0e0e0;
}

.has-error .ant-input,
.has-error .ant-input:hover {
  background-color: #fff;
  border-color: #ff6636;
}

.has-error .ant-form-explain,
.has-error .ant-form-split {
  color: #ff6636;
}
.ant-checkbox-inner {
  width: 18px;
  height: 18px;
  background-color: #fff;
  border: 1px solid #8c93ab;
  border-radius: 3px;
}
.ant-checkbox-wrapper:hover .ant-checkbox-inner,
.ant-checkbox:hover .ant-checkbox-inner,
.ant-checkbox-input:focus + .ant-checkbox-inner {
  border-color: $primary;
}
.ant-checkbox-checked {
  .ant-checkbox-inner {
    background-color: $primary;
    border-color: $primary;
  }
}
.ant-checkbox-inner::after {
  top: 50%;
  left: 21%;
  width: 6.242857px;
  height: 10.928571px;
}
.ant-form-item-label > label.ant-form-item-no-colon::after {
  content: ' ';
  display: none;
}
.filter__flex__container .ant-form-item-control {
  min-width: 225px;
}
