// Import mixins
@import 'assets/styles/mixins.scss';

/////////////////////////////////////////////////////////////////////////////////////////
/*  ANT DESIGN FORM */

.ant-radio-wrapper,
.ant-checkbox-wrapper {
  color: $text;
}

.ant-form-item-label > label {
  color: #8c93ab;
  margin-bottom: 0px;
  font-weight: 400;
  .form__label {
    font-weight: 400;
  }
}

.ant-form-item-label {
  white-space: normal;
}
