@import '../../assets/styles/scss/styleGuide/variables';
.redirectionModal {
  .modalContent {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    z-index: 1;
    .textContent {
      background-color: rgba(0, 0, 0, 0.7);
      color: var(--white);
      font-size: 1.4rem;
      font-weight: 500;
      padding: 16px;
      text-align: center;
    }
    .buttonContent {
      padding: 16px;
      text-align: center;
      width: 100%;
    }
    .buttonContent {
      button {
        background-color: var(--white);
        border: 0;
        color: var(--primary-color);
        cursor: pointer;
        display: inline-block;
        font-size: 1.1rem;
        font-weight: 600;
        margin: 0 8px;
        padding: 10px 24px;
        @include rounded-corners(20px);
      }
    }
  }
  .ant-modal-body {
    padding: 0;
    position: relative;
  }
  .ant-modal-close {
    border: 1px solid var(--white);
    margin: 12px;
    z-index: 2;
    @include rounded-corners(100px);
    .ant-modal-close-x {
      color: var(--white);
      height: 30px;
      line-height: 30px;
      width: 30px;
    }
  }
}
