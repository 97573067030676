// Override antd styles here
// actual antd styles are in less files in node_modules/antd
@import 'assets/styles/antd/style';
@import 'assets/styles/scss/bootstrap.scss';
@import 'assets/styles/custom';
@import 'assets/styles/mixins';
@import 'assets/styles/scss/styleGuide/styleguideComponents.scss';

body,
html {
  scroll-behavior: smooth;
}
body {
  font-family: var(--primary-font);
  font-weight: 500;
}
.content__container {
  background-color: #f7f9fa;
}

// Tooltip
.ant-tooltip-placement-bottomLeft {
  padding-top: 0px;
  top: 280 !important;

  .ant-tooltip-content {
    .ant-tooltip-arrow {
      &::before {
        background-color: transparent;
      }
    }
    .ant-tooltip-inner {
      -webkit-box-shadow: 0px 0px 20px #d6dce5;
      box-shadow: 0px 0px 20px #d6dce5;

      font-weight: bold;
      font-size: 12px;
      line-height: 18px;
      letter-spacing: 0.1px;
      border-radius: 4px;
      border: 1px solid #e9ecf7;
    }
  }
}
//////PIVOT TABLE////////
.ant-table-thead > tr > th {
  font-weight: 600 !important;
}

.pvtTable.no-footer.dataTable {
  top: -10px;
  position: inherit;
}
.pvtUi {
  color: #2a3f5f !important;
}
.tablesorter-default th,
.tablesorter-default thead td {
  color: #2a3f5f !important;
}
.row-fluid.input-append {
  .form-control {
    top: -5px;
    position: relative;
    left: 3px;
  }
}

:fullscreen div[id*='hdScrolltbl'] {
  height: 1000px !important;
}
div[id*='hdtbl'] {
  top: 29px !important;
}
table.pvtTable {
  font-size: 12px !important;
}
table.pvtTable thead tr th,
table.pvtTable tbody tr th {
  font-size: 12px !important;
}
.hide_scroll {
  overflow-x: hidden !important;
  overflow-y: hidden !important;
}

:fullscreen .pvtRendererArea {
  max-height: 100%;
  max-width: 100%;
  top: 0%;
}
.pvtRendererArea {
  top: 6%;
  position: absolute;
  max-height: 80%;
  max-width: 96%;
  overflow: auto;
}
.pvtVals.pvtUiCell {
  position: absolute;
  right: 3%;
}
.pvtTable {
  border-collapse: separate !important;
  border-spacing: 0;
}

.pvtTable {
  margin: 3px 3px !important;
}

.pvtTotalLabel.pvtRowTotalLabel {
  text-align: center !important;
}
.pvtTotalLabel.pvtColTotalLabel {
  text-align: left !important;
}
.product_analysis_action {
  margin: 0 5px;
  float: right;
  button.ant-btn {
    border-radius: 2px;
    padding: 0;
  }
}
.tabel_container {
  width: 100%;
  height: 1000px;
}
table.pvtTable thead tr th,
table.pvtTable tbody tr th {
  background-color: #f4f1f1 !important;
  border: 1px solid #e8e8e8 !important;
}

table.pvtTable tbody tr td {
  border: 1px solid #e8e8e8 !important;
}

.pvtGrandTotal {
  border: 1px solid #e8e8e8 !important;
  background-color: $white;
  text-align: right !important;
}

.pvtTotal.colTotal {
  background-color: $white !important;
}

#output {
  cursor: pointer;
}
//Filter showModal

.ant-tabs .ant-tabs-left-bar .ant-tabs-tab {
  text-align: left;
}

.ant-card-hoverable.card-selected {
  box-shadow: 0 0 3px 1px lighten($primary, 20%);
  &:hover {
    box-shadow: 0 0 3px 1px lighten($primary, 20%);
  }
}

.form__label {
  font-weight: 500;
  display: inline;
}

.drawer__content {
  margin-bottom: 60px;
}

.drawer__bottom__wrapper {
  position: absolute;
  right: 0;
  bottom: 0;
  width: 100%;
  border-top: 1px solid #e9e9e9;
  padding: 10px 16px;
  background: $white;
  text-align: right;
}

.drawer__button {
  width: 120px;
  border-radius: 2px;
}

.inline-block {
  display: inline-block;
}

.date__picker {
  width: fit-content;
}

.search__match {
  color: #f50;
}

.button__save {
  background: $success;
  border-color: $success;
  color: $white;
  &:hover,
  &:focus {
    background: lighten($color: $success, $amount: 10%);
    color: $white;
    border-color: $success;
  }
}
.button__cancel {
  background: $white;
  border-color: $gray-6;
  color: $gray-6;
  &:hover,
  &:focus {
    background: $white;
    color: #000;
    border-color: #000;
  }
}
.button__delete {
  background: $danger;
  border-color: $danger;
  color: $white;
  &:hover,
  &:focus {
    background: lighten($color: $danger, $amount: 10%);
    border-color: $danger;
    color: $white;
  }
}

.topbar__dropdown__menu {
  margin-top: 15px;
}
.ask__aarya__button {
  margin-right: 20px;
}

.dropdown__icon {
  margin: auto;
  margin-top: 10px;
}

.card__wrapper {
  margin-bottom: 20px;
}

.dashboard_active.ant-menu #dashboard,
.data_sources_active.ant-menu #data_sources,
.exploration_active.ant-menu #explore,
.segmentation_active.ant-menu #segments,
.exploration_active.ant-menu #exploration,
.segmentation_active.ant-menu #segmentation {
  .hoverContainer {
    background-color: #e6f7ff !important;
  }
  &::before {
    position: absolute;
    width: 4px;
    height: 32px;
    left: 0;
    top: 11px;
    background: var(--primary-color);
    content: '';
    @include rounded-corners(0 100px 100px 0);
  }
}

.login__main__icon__wrapper {
  padding: 30px 20px;
  .login__main__icon {
    width: 100%;
  }
}

.info__popup {
  font-size: 12px;
  font-weight: bold;
}

.info__popup__datasource {
  margin-bottom: 10px;
}

// LAYOUT
.content__container {
  position: absolute;
  width: 100%;
  overflow: auto;
  height: 100%;
  -webkit-overflow-scrolling: touch;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
}

.sidebar__container {
  background: var(--white);
  box-shadow: 0 4px 10px #e8f0f3;
}

.layout__main__container {
  position: relative;
  flex-grow: 1;
  overflow: hidden;
}

.c-pageContainer {
  display: flex;
  position: relative;
  height: auto;
  min-height: 100%;
  overflow: hidden;
  .sidebar__menu {
    position: fixed;
    height: 100%;
  }
}

// TOPBAR
.topbar__wrapper {
  padding: 15px 20px;
}
.topbar__content {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.topbar__content__right {
  display: flex;
  flex-direction: row;
}
.topbar__content__left {
  margin: auto 0;
}
.topbar__account__wrapper {
  margin-left: -20px;
}
.topbar__account__select {
  max-width: 300px;
  width: 200px;
  color: #000;
  font-size: 1.17em;
  border-radius: 2px;
  border: 1px solid #eee;
  margin: 0 20px !important;
  .ant-select-selection {
    border: none;
    box-shadow: none;
  }
  .ant-select-selection--single {
    .ant-select-selection__rendered {
      padding-right: 10px;
    }
  }
}
.topbar__search {
  width: auto;
  margin: 0 40px;
  box-shadow: 1px 0 5px 2px rgba(0, 0, 0, 0.1);
  .ant-input-group {
    & > .ant-input:first-child {
      border-radius: 2px;
      padding-right: 30px;
      border: none;
    }
    .ant-input-group-addon {
      position: absolute;
      right: 27px;
      top: 3px;
      .ant-input-search-button {
        border-radius: 2px;
        width: 25px;
        height: 25px;
        margin: auto;
        padding: 0;
        z-index: 9;
      }
    }
  }
}

.topbar__text {
  margin: 0 10px;
  color: #000;
  font-size: 1.17em;
}
.topbar__greeting {
  margin: auto;
}

// DETAIL BAR
.detailbar__container {
  text-align: left;
  border-left: 1px solid #f3f3f3;
  position: fixed;
  height: 100%;
  background: var(--white);
  .toggle__wrapper {
    position: absolute;
    top: 20px;
    right: -13px;
    .ant-btn-sm {
      font-size: 12px;
    }
    .ant-btn:focus {
      color: rgba(0, 0, 0, 0.65);
      background: $white;
      border-color: #d9d9d9;
    }
  }
}

.customNavLinks {
  .ant-menu-item-group-list {
    top: '-6px';
    position: 'relative';
    z-index: 2;
    background: '#fff';
  }
  .ant-menu-item-group-title {
    display: none;
  }
}
.detailbar__menu {
  width: 240px;
  border: none;
  font-size: 14px;
  color: #4d5a74;
  padding: 20px 10px;
  .SideLogo {
    margin-bottom: 50px;
    padding-left: 10px;
    margin-top: 10px;
    span {
      display: block;
      text-transform: uppercase;
      font-size: 14px;
      font-weight: 700;
      line-height: 18px;
      letter-spacing: 0.6px;
      color: $black;
    }
  }
  .ant-menu-item-group-title {
    font-size: 10px;
    letter-spacing: 0.5px;
    border-bottom: 1px solid #e9ecf7;
    color: #4d5a74;
    font-weight: normal;
    padding: 10px 10px;
  }
  .ant-menu-item-group-list {
    overflow-y: auto;
    overflow-x: hidden;
    max-height: 70vh;
    scrollbar-color: #f8f8f8 #f8f8f8;
  }
  .ant-menu {
    background: #f8f8f8;
  }
  .ant-menu-item {
    padding-left: 0px !important;
    padding-right: 0px;
    &.connected {
      padding-left: 10px !important;
    }
    a {
      padding-left: 20px !important;
      &::before {
        display: none;
      }
      &:hover {
        color: $primary;
        padding-left: 20px !important;
        border-radius: 4px;
        margin-bottom: 5px;
        text-decoration: none;
        background: #f7f9fa;
      }
    }
    &.all-data {
      border-bottom: 1px solid #e9ecf7;
      height: auto;
      padding-left: 0px !important;
      padding-right: 0px;
      padding-top: 5px;
      padding-bottom: 5px;
      a {
        background: #f7f9fa;
        border-radius: 4px;
        margin-bottom: 5px;
        padding-left: 10px !important;
        &:hover {
          color: $primary;
          background: #f7f9fa;
          border-radius: 4px;
          margin-bottom: 5px;
          text-decoration: none;
        }
      }
    }
    .ant-avatar {
      -webkit-box-sizing: border-box;
      box-sizing: border-box;
      margin: 0;
      padding: 0;
      color: rgba(0, 0, 0, 0.65);
      font-size: 14px;
      font-variant: tabular-nums;
      line-height: 1.5;
      list-style: none;
      -webkit-font-feature-settings: 'tnum', 'tnum';
      font-feature-settings: 'tnum', 'tnum';
      position: relative;
      display: inline-block;
      overflow: hidden;
      color: $white;
      white-space: nowrap;
      text-align: center;
      vertical-align: middle;
      background: #ccc;
      width: 24px;
      height: 24px;
      line-height: 24px;
      border-radius: 50%;
      margin-left: 8px;
    }
  }
  .user__menu {
    padding-top: 50px !important;
    .ant-menu-item {
      letter-spacing: 0.1px;
      font-weight: normal;
      font-size: 14px;
      &:hover {
        background: #eef4fb;
        color: #4d5a74;
        border-radius: 4px;
      }
    }
  }
  .ant-menu-item.ant-menu-item-selected {
    padding-left: 0px !important;
    background: transparent;
    padding-right: 0px;
    a {
      color: $primary;
      padding-left: 20px !important;
      border-radius: 4px;
      margin-bottom: 5px;
      text-decoration: none;
      background: #f7f9fa;
    }
    &:after {
      display: none;
    }
  }
}
.ant-dropdown-menu {
  .search-container {
    padding: 5px 20px;
    &.forStoreSearch {
      position: relative;
      .search-input {
        padding: 14px 14px 14px 32px;
        outline: none;
        width: 100%;
        max-width: 240px;
      }
      .anticon {
        position: absolute;
        top: 19px;
        left: 30px;
      }
    }
  }
}
.detailbar__button__wrapper {
  margin-top: 30px;
  text-align: center;
  button {
    width: 90%;
  }
}

//SIDEBAR
.sidebar__wrapper {
  height: 100%;
  z-index: 999;
}
.sidebar__menu {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  width: 100px;
  .ant-menu-item {
    text-align: center;
  }
}

.sidebar__menu__bottom {
  .ant-menu-item:hover {
    .anticon {
      &::before {
        height: 28px;
        width: 28px;
        position: absolute;
        content: '';
        background: $white;
        margin-top: 7px;
        left: 18px;
        border-radius: 4px;
      }
    }
  }
}
.sidebar__menu__label {
  line-height: 1;
  font-size: 12px;
}

.sidebar__logo {
  text-align: center;
  padding: 0;
  height: 50px;
  width: 50px;
  margin: 15px auto;
  .sidebar_link {
    display: block;
    img {
      height: 50px;
      width: 50px;
    }
  }
}

// -- filter sidebar
.c-filterSidebar {
  &.ant-drawer-open {
    .ant-drawer-mask {
      background-color: rgba(0, 0, 0, 0.45) !important;
      opacity: 1 !important;
    }
  }
  .ant-drawer-header {
    height: auto;
    .ant-drawer-close {
      .anticon {
        position: relative;
        left: 20px;
      }
    }
  }
  .ant-drawer-body {
    overflow-y: auto;
  }
}

// COMPONENT LAYOUT (DASHBOARD/INTEGRATIONS PAGE)
.component__content {
  min-height: calc(100vh - 100px);
  justify-self: center;
  border-radius: 4px;
  &.segments__content {
    margin: 0px 20px 20px 20px;
  }
}

.component__content__wrapper {
  margin-left: 20px;
  display: grid;
  grid-template-columns: 1fr;
  min-height: calc(100vh - 105px);
  &.component__container {
    padding-left: 236px;
  }
}
.component__content__container {
  padding: 0 0px;
}
.component__content {
  margin: 0 auto 10px;
  min-height: calc(100vh - 100px);
  justify-self: center;
  margin-top: 105px;
}

//DASHBOARD CREATE
// DashBoard Create - New Page

.create_input_wrapper {
  display: flex;
  justify-content: space-between;
  margin-bottom: 0px;
}
.creat_dash_heading_wrapper {
  margin-top: -170px !important;
  padding-bottom: 0px !important;
}
.create_chart_container {
  margin-top: 170px;
  .create_chart_metric_wrapper {
    display: flex;
    justify-content: space-between;
    margin-right: 24px;

    .create_chart_wrapper {
      width: calc(100% - 280px);
    }
  }
}

.heading_text {
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
  color: #4d5a74;
  margin-bottom: 16px;
}

.metrics_input_wrapper {
  display: flex;
  justify-content: space-between;
  margin-bottom: 40px;
  align-items: center;
}

.metrics_filter_wrapper {
  display: flex;
  flex-direction: column;
  width: 282px;

  .metrics_filter_checkbox_wrapper {
    display: flex;
    flex-direction: column;
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: 0.1px;
    color: #4d5a74;
    overflow-y: scroll;
    height: 395px;

    .metrics_checkbox {
      min-height: 40px;
      display: flex;
      align-items: center;
      margin-left: 8px;

      &:hover {
        background: #f7f9fa;
      }
    }
  }
}

.metric_modal_header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-right: 20px;
  .title {
    font-style: normal;
    font-weight: 600;
  }
}

.metrics_list {
  position: fixed;
  right: 35px;
  width: 280px;
}
.metrics_list_modal_wrapper {
  display: flex;
  flex-wrap: wrap;

  .metric_card_modal_wrapper {
    margin-right: 10px;
    margin-bottom: 10px;
    &:nth-child(3n + 3) {
      margin-right: 0;
    }
  }
  &.cardGrid {
    display: grid;
    gap: 16px;
    grid-auto-flow: row;
    grid-template-columns: repeat(3, minmax(150px, 1fr));
    .metric_card_modal_wrapper {
      margin: 0;
      padding: 0;
      .metric_card_wrapper {
        width: auto;
        margin-bottom: 0;
      }
    }
  }
}

.metric_card_wrapper {
  display: flex;
  flex-direction: column;
  padding: 16px 16px 24px 16px;
  width: 282px;
  background: #ffffff;
  box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.25);
  border-radius: 4px;
  height: 186px;
  margin-bottom: 10px;

  .metric_card_title_container {
    justify-content: space-between;
    display: flex;
    margin-bottom: 9px;
    .title {
      font-weight: 600;
      font-size: 18px;
      line-height: 24px;
      color: #4d5a74;
    }
  }
  .metric_card_description {
    font-size: 12px;
    line-height: 18px;
    color: #4d5a74;
    margin-bottom: 32px;
  }
  .metric_icons_wrapper {
    .metric_icon {
      display: inline-flex;
      background: #f9f9f9;
      margin: 0 16px 10px 0;
      padding: 6px;
      border-radius: 100%;
      justify-content: center;
      align-items: center;
      border: 1px solid #f7f7f7;
    }
  }
}

.dashboard__template__container {
  margin-bottom: 10px;
}
.dashboard__template__item {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 300px;
  .ant-card-head-title {
    font-size: 18px;
    font-weight: bold;
    color: #4d5a74;
    padding-bottom: 8px !important;
  }
  .dashboard__template__description {
    overflow: hidden;
    -webkit-line-clamp: 6;
    -webkit-box-orient: vertical;
    white-space: inherit;
    height: 100%;
    line-height: 20px;
    display: -webkit-box;
    text-overflow: ellipsis;
  }
}

.dashboard__template__select__button__wrapper {
  text-align: center;
  margin-bottom: 20px;
}
.dashboard__template__select__button {
  padding: 0 3em;
}

.dashboard__template__select__button__wrapper {
  text-align: center;
  margin-bottom: 20px;
}
.dashboard__template__select__button {
  padding: 0 3em;
}
.data__sources__image__wrapper {
  text-align: center;
  .data__sources__image {
    margin-top: 40px;
    max-height: 60px;
    max-width: 100%;
  }
}
.data__sources__card__title {
  font-size: 1.1em;
  font-weight: bold;
  text-align: center;
  margin-top: 20px;
  bottom: 90px;
}

// DASHBOARD VIEW
.dashboard__name__input.ant-input {
  width: 227px;
  height: 40px;
  background: $white;
  border: 1px solid #d6dce5;
  box-sizing: border-box;
  box-shadow: inset 0px 1px 2px #e0e0e0;
  border-radius: 6px;
  font-weight: bold;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: 0.1px;
  color: #4d5a74;
  &::selection {
    background: none;
  }
  &:focus {
    border: none;
    box-shadow: none;
  }
}

.brand {
  margin-top: 5px;
}

.header__wrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-bottom: 10px;
}
.add__integration {
  .header__wrapper {
    padding-bottom: 0px;
  }
}
.header {
  .header_title {
    font-size: 18px;
    line-height: 24px;
    font-weight: 500;
    color: #4d5a74;
  }
  .header_timer {
    font-weight: 400;
    font-size: 10px;
    line-height: 12px;
    letter-spacing: 0.1px;
    color: #8c93ab;
  }
}
.header__action {
  margin: 0 5px;
  button.ant-btn {
    width: 6.5em;
    border-radius: 2px;
    padding: 0;
  }

  &.create {
    button.ant-btn {
      border-radius: 2px;
      padding: 0 15px;
      width: auto;
    }
  }
}

.kpi__search__wrapper {
  .kpi__search {
    margin-bottom: 20px;
  }
  .kpi__tree {
    padding: 50px 40px;
  }
}

.kpi__drawer__button {
  margin-top: 30px;
  margin-right: 20px;
  text-align: right;
}

// ------- Remove after all fixes ------
.react-resizable-handle {
  display: none;
}

.ant-modal-body {
  .metric__value {
    font-size: 42px;
    line-height: 48px;
    letter-spacing: -1px;
    position: relative;
    padding-top: 0px;
    display: block;
  }
  .metric__name {
    color: #8c93ab;
    font-size: 12px;
    display: block;
  }
  .MuiTypography-colorTextSecondary span {
    display: inline-block;
    padding: 0 0px;
    margin-top: 7px;
    font-size: 12px !important;
    line-height: 12px !important;
    letter-spacing: 0.1px;
    color: #8c93ab !important;
    font-weight: 400 !important;
  }
}

.widget__actions {
  display: flex;
  align-items: center;
  .update__icon {
    font-size: 18px;
    margin: 0 3px;
    display: none;
  }
  .time__granularity__select {
    font-size: 12px;
    color: #000;
    width: 85px !important;
    .ant-select-selection {
      border: none;
      border-radius: 0;
      box-shadow: none;
      .ant-select-selection__rendered {
        margin-right: 15px;
        display: flex;
        .ant-select-selection-selected-value {
          font-size: 14px;
        }
      }
    }
  }
}

.widget__action {
  button {
    border: none;
    color: #000;
    &:hover {
      border: none;
      color: $white;
    }
  }
  &.hidden {
    display: none;
  }
}

// INTEGRATIONS
.integration_searchContainer {
  width: 100%;
  position: absolute;
  right: 0;
  z-index: 9;
  top: -65px;
  max-width: 280px;
  .search-input {
    width: 100%;
  }
  .anticon-search {
    left: 10px;
    top: 14px;
  }
}
.integration__view__wrapper {
  .tbl__list {
    position: relative;
  }
}
.integration__status__wrapper {
  display: inline-block;
  vertical-align: middle;

  .integration__status {
    color: var(--white);
    display: inline-block;
    position: relative;
    text-align: center;
    font-size: 14px;
    line-height: 18px;
    font-weight: 600;
    padding: 4px 16px;
    @include rounded-corners(40px);
    &.active {
      background: $success;
    }
    &.inactive {
      background: $danger;
    }
  }
}
.integration__accounts__wrapper {
  .account__name__label {
    display: inline-block;
    font-weight: 500;
  }
  .account__name {
    display: inline-block;
  }
}
.visualisation__list__wrapper {
  margin-top: 0px;
  background: $white;
  box-shadow: 8px 8px 20px #f9f9f9;
  border-radius: 4px;
  padding: 20px;
  font-size: 14px;
  color: #4d5a74;
  padding-top: 5px;
  margin-bottom: 20px;
}
.visualisation__list__title {
  font-size: 1.17em;
  font-weight: bold;
  margin: 10px 0;
}
.visualisation__list__sub_title {
  color: #8c93ab;
  font-size: 14px;
  padding-bottom: 20px;
}

.integration__form__account {
  margin-left: 20px;
}
.form__org__name {
  font-size: 14px;
  font-weight: 500;
}
.oauth__action__wrapper {
  text-align: center;
}
.oauth__connect__button {
  margin: 0 auto;
  width: 300px;
  text-align: center;
  & > :first-child {
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    width: 42px;
    line-height: 34px;
    text-align: center;
    border-right: 1px solid rgba(0, 0, 0, 0.2);
    &.fa-google {
      display: inline-block;
      background: transparent;
      line-height: 33px;
      & > img {
        width: 28px;
      }
      &::before {
        display: none;
      }
    }
  }
}
.oauth__action__wrapper {
  .btnSocial {
    display: inline-block;
    background-color: #4285f4;
    border: none;
    color: $white;
    height: 50px;
    min-width: 200px;
    cursor: pointer;
    outline: none;
    position: relative;
    padding: 10px 14px 10px 55px;
    font-size: 16px;
    font-weight: 600;
    @include box-shadow(0 2px 4px 0 rgba(0, 0, 0, 0.25));
    @include rounded-corners(5px);
    .oauth_img {
      position: absolute;
      top: 2px;
      left: 1px;
      height: 48px;
      width: 48px;
      padding: 8px;
      img {
        height: 32px;
        width: 32px;
      }
    }
    &.button__google {
      color: $white;
    }
    &.button__facebook {
      .oauth_img {
        top: 2px;
        left: 7px;
      }
    }
  }
}

.button__facebook {
  color: $white;
  background-color: #3b5998;
  &:hover {
    color: $white;
    background: #3b5998;
    background-color: #3b5998;
    box-shadow: 2px 2px 5px 1px rgba(0, 0, 0, 0.2);
  }
}

.button__google {
  background: white;
  color: #444;
  font-weight: bold;
  &:hover {
    box-shadow: 2px 2px 5px 1px rgba(0, 0, 0, 0.2);
  }
}

.form__orgs__wrapper {
  margin-bottom: 30px;
}

//AARYA PAGE

.aarya__container__wrapper {
  margin-top: 50px;
  padding: 10px;
}
.aarya__title {
  h1 {
    font-weight: 500;
    font-size: 1.7em;
  }
}
.aarya__input__wrapper {
  margin-bottom: 20px;
}
.tab__content__wrapper {
  background-color: #f5f5f5;
}
.tab__content {
  padding: 30px 20px;
  min-height: 400px;
}
.form__org__name {
  font-size: 14px;
  font-weight: 500;
}
.oauth__action__wrapper {
  text-align: center;
}
.oauth__connect__button {
  margin: 0 auto;
  width: 300px;
  text-align: center;
  & > :first-child {
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    width: 42px;
    line-height: 34px;
    text-align: center;
    border-right: 1px solid rgba(0, 0, 0, 0.2);
    &.fa-google {
      display: inline-block;
      background: transparent;
      line-height: 33px;
      & > img {
        width: 28px;
      }
      &::before {
        display: none;
      }
    }
  }
}

.button__facebook {
  color: $white;
  background-color: #3b5998;
  &:hover {
    color: $white;
    background: #3b5998;
    background-color: #3b5998;
    box-shadow: 2px 2px 5px 1px rgba(0, 0, 0, 0.2);
  }
}

.button__google {
  background: white;
  color: #444;
  font-weight: bold;
  &:hover {
    box-shadow: 2px 2px 5px 1px rgba(0, 0, 0, 0.2);
  }
}

.form__orgs__wrapper {
  margin-bottom: 30px;
}

//AARYA PAGE

.ask__aarya__wrapper {
  text-align: center;
  background: #daeaf8;
  padding: 50px 0 30px;
}
.aarya__input__wrapper {
  text-align: center;
  margin-bottom: 20px;
}
.aarya__title {
  .ask__aarya__image {
    width: 80px;
    position: relative;
    top: -20px;
  }
  h1 {
    display: inline-block;
    margin-bottom: 20px;
    font-size: 2em;
    color: #101010;
  }
}
.ask__aarya__input {
  max-width: 700px;
  width: 70vw;
  border-radius: 3px;
  box-shadow: 0 8px 20px 0 rgba(167, 171, 181, 0.94);
  background-color: $white;
  .ant-input-group {
    & > .ant-input:first-child {
      border-radius: 3px;
      padding-right: 30px;
      height: 55px;
      border: none;
    }
    & > .ant-input:focus {
      box-shadow: none;
    }
    .ant-input-group-addon {
      .ant-input-search-button {
        border-radius: 3px;
        width: 100px;
        height: 45px;
        margin-right: 10px;
        padding: 0;
      }
    }
  }
}
.aarya__questions__wrapper {
  margin: 40px;
  max-width: 1200px;
  margin: 40px auto;
}
.insight__question__wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.insight__question {
  font-weight: 500;
  font-size: 1.1em;
}
.insight__question__status {
  padding: 2px 7px;
  border-radius: 3px;
  // background: $white;
  margin-left: 10px;
}
.insight__answer__wrapper {
  position: relative;
  min-height: 40px;
  margin: 0 25px;
  text-indent: 100px;
}
.download__report {
  margin-top: 30px;
  text-align: right;
}

//TEMP
.ant-tabs-bar {
  margin: 0 !important;
}

//Segments
//GLOBAL - Start
.show_all_btn {
  font-weight: bold;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: 0.1px;
  color: #245ea4;
  padding: 0;
}

.segment_form_input_wrapper {
  margin-bottom: 0px;
  width: 225px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  .segment_form_label {
    font-size: 12px;
    line-height: 18px;
    letter-spacing: 0.1px;
    color: #8c93ab;
    &::after {
      display: flex;
    }
  }
}

.boolean_filter_field_wrapper {
  display: inline-flex;
  .boolean_filter_field_title {
    font-size: 12px;
    line-height: 18px;
    letter-spacing: 0.1px;
    color: #4d5a74;
    margin-left: 9px;
  }
  .boolean_filter_field_container {
    margin-right: 27px;
  }
}

.multiselect_filter_field_wrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  .multiselect_filter_field_title {
    font-size: 12px;
    line-height: 18px;
    letter-spacing: 0.1px;
    color: #8c93ab;
  }
}
.grouping_label {
  display: block;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: 0.1px;
  color: #8c93ab;
  margin-bottom: 7px;
}
.segment_filters_heading {
  margin: 10px 0 32px 0;

  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  color: #4d5a74;
  position: relative;
  top: 50px;
  .ant-col:first-child {
    padding-left: 10px;
  }
  .ant-col:last-child {
    padding-left: 20px;
  }
}

//GLOBAL - End
.export_dropdown {
  background: $white;
  box-shadow: 0px 0px 10px #d6dce5;
  border-radius: 4px;
  .export_item_title {
    color: #4d5a74;
    &:hover {
      background: #eef4fb;
      border-radius: 4px;
      margin: 2px;
    }
  }
}

.segment_pie_chart_wrapper {
  display: flex;
  flex-direction: column;
  .users {
    font-weight: 500;
    font-size: 28px;
    line-height: 32px;
    color: #263238;
  }
  .total_users {
    font-size: 12px;
    line-height: 18px;
    letter-spacing: 0.1px;
    color: #8c93ab;
  }
  .heading {
    font-size: 14px;
    line-height: 20px;
    margin-top: 18px;
  }
}

.segments__view__wrapper_create {
  margin: 10px 20px;
  background: #f7f9fa;
  margin-top: 160px;
  .header__wrapper_create {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 10px;
  }

  .filter_wrapper {
    padding: 10px 20px 20px 10px;
    background: $white;
    box-shadow: 8px 8px 20px #f9f9f9;
    border-radius: 4px;
    .header {
      padding-left: 10px;
    }
    .filters__wrapper input.ant-calendar-picker-input.ant-input {
      margin-left: 8px;
    }
  }
}

// ----- Create segment right pie chart bar
.segment_chartContainer {
  .filter_summary_wrapper {
    text-align: center;
    padding: 0 0 50px;

    .segment__user__count__title {
      margin-bottom: 30px;
    }

    .pie_chart_heading {
      text-align: center;
      font-weight: 500;
      font-size: 18px;
      line-height: 24px;
      color: #4d5a74;
      margin-bottom: 32px;
    }
  }
  .ant-progress {
    .ant-progress-circle {
      .ant-progress-circle-trail {
        stroke: #cfd8dc !important;
      }
      .ant-progress-circle-path {
        stroke: #6b9ce8 !important;
      }
    }
  }

  // Segment Sidebar InfoChip Title and Values
  .criteria_list_wrapper {
    font-size: 12px;
    line-height: 18px;
    font-weight: 600;
    letter-spacing: 0.1px;
    color: var(--primary-color);

    .criteria_list {
      .criteria_value_container {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        margin: 5px 0 16px 0;

        .criteria_value,
        .chip {
          color: #245ea4;
          font-weight: bold;
          background-color: #eef4fb;
          border-radius: 99px;
          padding: 3px 12px;
          margin-right: 8px;
          margin-bottom: 5px;
        }
      }
    }
  }
}

.segment_infobar_wrapper {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: flex-end;
  overflow: scroll;
  position: relative;

  .segment_infobar_overlay {
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-color: rgba(255, 255, 255, 0);
    z-index: 90;
  }

  .segment_infobar__details {
    z-index: 100;
    width: 396px;
    height: fit-content;
    background: $white;
    box-shadow: 0px 20px 50px rgba(232, 235, 240, 0.7);
    padding: 30px 24px;
    position: fixed;
    height: 100%;
    overflow: hidden;
    top: 0;
    right: 0;

    .title_wrapper {
      display: flex;
      flex-direction: row;

      justify-content: space-between;

      .title {
        font-weight: 500;
        font-size: 18px;
        line-height: 24px;
        color: #4d5a74;
        max-width: 200px;
      }

      .timer {
        font-size: 10px;
        line-height: 12px;
        letter-spacing: 0.1px;
        color: #8c93ab;
      }

      .icon_container {
        font-size: 14px;
        .icon {
          padding: 0 6px;
          &:hover {
            background: #eef4fb;
          }
        }
        .ant-btn {
          background: transparent;
          border: transparent;
          padding: 5px 8px;
          &:hover {
            background: #eef4fb;
            border: transparent;
            svg {
              path {
                fill: #245ea4;
              }
            }
          }
          &:focus {
            background: transparent;
            border: transparent;
          }
        }
      }
    }

    .segment_short_intro {
      display: flex;

      justify-content: space-around;
      align-items: center;

      .intro_container {
        display: flex;
        flex-direction: column;

        .intro_title {
          font-size: 10px;
          line-height: 12px;
          letter-spacing: 0.1px;
          color: #8c93ab;
        }

        .intro_value {
          font-style: normal;
          font-weight: 500;
          font-size: 24px;
          line-height: 30px;
          color: #1b314b;
          margin-top: 8px;
        }
      }
    }

    .segment_description {
      display: flex;
      flex-direction: column;

      margin-top: 32px;
      font-size: 12px;
      line-height: 18px;
      letter-spacing: 0.1px;

      .description_title {
        color: #8c93ab;
      }

      .description_details {
        margin-top: 4px;
        color: #4d5a74;
      }
    }
  }

  .criteria_header {
    display: flex;
    justify-content: space-between;
    align-items: center;

    font-weight: 500;
    font-size: 18px;
    line-height: 24px;
    color: #4d5a74;
    margin-top: 32px;
  }
}

.description_container {
  display: flex;
  flex-direction: column;
  justify-content: center;

  .title {
    color: var(--primary-color);
    font-weight: 800;
    font-size: 1.4rem;
    line-height: 2rem;
    letter-spacing: 0.5px;
    margin-bottom: 12px;
  }

  .details {
    font-size: 1.1rem;
    line-height: 1.4rem;
    color: var(--fontColor-light);
    margin-bottom: 24px;
  }
}

.segment_form_wrapper {
  overflow-y: auto;

  .ant-drawer-header {
    background: $white;
    height: 84px;
    display: flex;
    align-items: center;

    .ant-drawer-title {
      font-style: normal;
      font-weight: 500;
      font-size: 18px;
      line-height: 24px;
      color: #4d5a74;
    }
    .ant-drawer-close {
      color: #8c93ab;
      display: flex;
      align-items: center;
      height: 100%;
    }
  }

  .segment_form {
    .icon_wrapper {
      min-height: 86px;
      display: flex;
      align-items: center;
      margin-left: 24px;

      .title {
        margin-left: 16px;

        font-weight: 900;
        font-size: 24px;
        line-height: 29px;
        color: #4d5a74;
      }
    }
    .form_wrapper {
      display: flex;
      flex-direction: column;
      margin: 0 24px;

      .segment_export_alert {
        font-size: 14px;
        line-height: 20px;
        letter-spacing: 0.1px;
        color: #2e73c6;
      }

      .form_item {
        display: flex;
        flex-direction: column;
        margin-bottom: 26px;
        .ant-form-item-label {
          line-height: 18px;
        }

        label {
          margin-bottom: 4px;
        }

        .form__label {
          display: inline-flex;

          font-size: 12px;
          line-height: 18px;
          letter-spacing: 0.1px;
          color: #8c93ab;
        }
      }
    }
  }
}
.integration__view__wrapper {
  .header__wrapper {
    padding-right: 0px;
  }

  .brand {
    margin-top: 14px;
  }
  &.segment__list {
    padding: 0 0px;
    .dashboard.header__wrapper {
      padding: 0px 20px 10px 20px;
    }
  }
}
.segments__view__wrapper {
  .segments__list__wrapper {
    .segment_wrapper {
      display: flex;
      flex-direction: column;
      align-items: flex-end;

      .loading_bar {
        background: #d6dce5;
        width: 50px;
        height: 6px;
        border: none;
        border-radius: 4px;
        margin-top: 4px;

        .loading_bar_filled {
          width: 24px;
          height: 6px;
          background: #4f97ee;
          border-radius: 4px;
        }
      }

      .loading_text {
        font-size: 8px;
        line-height: 10px;
        letter-spacing: 0.1px;
        color: #8c93ab;
        display: inline-block;
        width: 50px;
        text-align: left;
      }
    }

    table {
      color: #245ea4;
      font-weight: bold;
      font-size: 12px;
      line-height: 18px;
      letter-spacing: 0.1px;

      th {
        background-color: #f7f9fa;
        color: #4d5a74;
        text-align: right;
        box-shadow: inset 0px -1px 0px #e9ecf7;
        border: none;
        font-size: 14px;

        &:last-child {
          padding-left: 40px;
        }
      }

      tr {
        background-color: $white;

        &:hover:not(.ant-table-expanded-row):not(.ant-table-row-selected) > td {
          background: #eef4fb;
        }
        td {
          &:last-child {
            padding-left: 25px;
          }
          &:first-child {
            color: #245ea4;
          }
        }
      }

      button {
        font-size: 16px;
      }
      .ant-btn {
        background: transparent;
        border: transparent;
        &:hover {
          background: transparent;
          border: transparent;
          svg {
            path {
              fill: #245ea4;
            }
          }
        }
        &:focus {
          background: transparent;
          border: transparent;
        }
      }
    }
    .ant-checkbox-inner {
      width: 18px;
      height: 18px;

      &::after {
        top: 42%;
        left: 15%;
        width: 7.642857px;
        height: 13.428571px;
      }
    }
  }
}
.ant-form-item-control {
  line-height: normal !important;
}

.segment.header__wrapper {
  margin-bottom: 20px;
  border: none;
  .header {
    margin-right: 20px;
    flex-grow: 1;
  }
}

// .builder__card__wrapper {
//   margin-top: 30px;
// }

.segment__name__input {
  font-size: 14px;
  border: none;
  border-radius: 0;
  padding: 0;
  box-shadow: none;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  padding-bottom: 5px;
  &.texarea {
    resize: none;
    min-width: 450px;
    min-height: 65px;
  }
  &:focus,
  &:active {
    box-shadow: none;
  }
  &.with-bigFont {
    display: block;
    font-size: 14px;
    line-height: 18px;
    padding: 11px 12px;
    border-radius: 6px;
  }
}

.has-error {
  input.segment__name__input {
    &:focus {
      box-shadow: none;
    }
  }
}

.filter__form__title__wrapper {
  margin-bottom: 10px;
  .filter__form__title {
    margin: 0;
  }
}

.tab__label {
  width: 100%;
  text-align: left;
}

.filter__flex__container {
  .filters__wrapper {
    height: 400px;
    overflow: auto;
    padding: 1px 0;

    .filter_divider {
      margin: 5px 0 15px 0;
      border-bottom: 1px solid #e9ecf7;
    }

    .filter__checkbox__wrapper {
      width: 100%;
    }
    .ant-btn {
      background: transparent !important;
      border: none !important;
      color: #d6dce5;
      padding: 8px 10px !important;
      &:hover {
        color: #245ea4 !important;
        background: #eef4fb !important;
      }
    }
  }
  .filter__field__wrapper {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    padding: 0;
    color: rgba(0, 0, 0, 0.65);
    font-size: 14px;
    font-variant: tabular-nums;
    line-height: 1.5;
    list-style: none;
    -webkit-font-feature-settings: 'tnum', 'tnum';
    font-feature-settings: 'tnum', 'tnum';
    padding-bottom: 24px;
    vertical-align: top;
    width: 100%;
    min-height: 40px;
  }
  .filter__field__title {
    color: #000;
  }
}

.filter__list__container {
  height: auto;
  overflow: auto;
  background: #f7f9fa;
  padding: 24px;

  .filter__list__category {
    display: flex;
    flex-direction: column;
    text-align: left;
  }
}

.filter__popup__item {
  padding-left: 10px;
}
.filter__popup__category {
  margin-bottom: 10px;
}

.segment__title__card {
  margin-bottom: 10px;
}

.segment__create__wrapper {
  text-align: right;
}

.segment__button__wrapper {
  text-align: right;
  .segment__button {
    margin-left: 10px;
    min-width: 150px;
  }
}

.segments__list__wrapper {
  margin-top: 20px;
  background-color: $white;
}

.segment__popup__detail__list {
  padding-left: 20px;
}

//UserAnalysis

.sunburst {
  cursor: pointer;
  display: block;
  margin: auto;
}

// Animations

.slide-fadein-up-enter {
  opacity: 0;
  transform: translateY(20px);
}

.slide-fadein-up-enter-active {
  opacity: 1;
  transform: translateY(0);
  transition: opacity 0.3s, transform 0.4s;
}

.slide-fadein-up-exit {
  opacity: 1;
  transform: translateY(0);
}

.slide-fadein-up-exit-active {
  opacity: 0;
  transform: translateY(-20px);
  transition: opacity 0.3s, transform 0.4s;
}

.detailbar-collapse-enter {
  transform: translateX(calc(20px - 100%));
}
.detailbar-collapse-enter-active {
  transform: translateX(0);
  transition: transform 300ms;
}

.detailbar-collapse-enter-done {
  transform: translateX(0);
}

.detailbar-collapse-exit {
  transform: translateX(0);
  // transition: transform 1s;
}

.detailbar-collapse-exit-active {
  transform: translateX(calc(20px - 100%));
  transition: transform 300ms;
}

.detailbar-collapse-exit-done {
  transform: translateX(calc(20px - 100%));
}

.component__content__wrapper.content-collapse-enter {
  padding-left: 0px;
}

.component__content__wrapper.content-collapse-enter-active {
  padding-left: 236px;
  transition: padding 300ms;
}

.component__content__wrapper.content-collapse-enter-done {
  padding-left: 236px;
}

.component__content__wrapper.content-collapse-exit {
  padding-left: 236px;
}

.component__content__wrapper.content-collapse-exit-active {
  padding-left: 0px;
  transition: padding 300ms;
}

.component__content__wrapper.content-collapse-exit-done {
  padding-left: 0px;
}

/*---- New Addde By shro021183 ----*/
.vh-100 {
  height: 100%;
}
.sign-in-bg {
  border-radius: 8px;
  .sign-in-bg-left {
    background-size: cover;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    .slide-text {
      color: $white;
      font-size: 18px;
      margin-top: 50px;
      text-align: left !important;
      h2 {
        font-weight: 900;
        font-size: 64px;
        line-height: 77px;
        letter-spacing: 0.7px;
        text-align: left !important;
        position: relative;
        &::after {
          content: '';
          position: absolute;
          height: 1px;
          width: 135px;
          border-bottom: 4px solid #e8913b;
          left: 0px;
          top: calc(100% + 20px);
        }
      }
    }
    img {
      max-width: 68%;
    }
  }
  .sign-in-bg-right {
    background: $white;
    background: $white;
    display: flex;
    flex-direction: column;
    justify-content: center;
    font-size: 14px;
    border-radius: 8px;
    .ant-input {
      height: 56px;
      font-size: 14px;
      border-radius: 6px;
      border: 1px solid rgba(195, 202, 217, 0.5);
      padding: 4px 20px;
    }
    form input[type='radio'],
    form input[type='checkbox'] {
      width: 16px;
      height: 16px;
    }
    .ant-checkbox-inner {
      width: 16px;
      height: 16px;
      border-radius: 3px;
      &:after {
        width: 6.642857px;
        height: 9.428571px;
      }
    }
    .auth__form__button {
      margin-top: 40px;
      margin-bottom: 15px;
      text-transform: uppercase;
    }
    .auth__form__forgotpass_button {
      margin-top: 22px;
      margin-bottom: 15px;
    }
    .socialicons {
      text-align: center;
      display: none;
      .icon {
        width: 23px;
        height: 23px;
        margin-left: 10px;
        margin-right: 10px;
        display: inline-block;
        text-decoration: none;
        &.fb {
          background: url('../src/assets/icons/facebook.svg') left top no-repeat;
        }
        &.linkedin {
          background: url('../src/assets/icons/linkedin.svg') left top no-repeat;
        }
        &.twitter {
          background: url('../src/assets/icons/twitter.svg') left top no-repeat;
        }
      }
    }
  }
  a {
    color: #2e73c6;
    font-weight: 700;
  }
  .ant-checkbox-wrapper {
    font-weight: 700;
    color: #8c93ab;
  }
  .metrics_filter_wrapper {
    .metrics_checkbox {
      margin-left: 8px;
    }
  }
  .login__main__icon {
    width: 100%;
    max-width: 165px;
    margin-bottom: 0px;
    display: none;
  }
  .ant-form-item-label > label {
    color: #8c93ab;
    margin-bottom: 0px;
  }
  .ant-form-item {
    margin-bottom: 10px;
  }
}
.ant-drawer-body {
  padding: 0px;
}

.ant-menu-item .anticon svg {
  fill: #245ea4;
  filter: grayscale(100%);
  opacity: 0.8;
  max-width: 22px;
  height: auto;
}
.ant-menu-item-selected .anticon svg {
  filter: grayscale(0%);
  opacity: 1;
}
.detailbar__container {
  .ant-menu {
    background: transparent;
  }
}

.ant-table-row-cell-break-word .ant-btn-primary {
  color: $white;
  background: #245ea4;
  border-color: #245ea4;
  height: 31px;
  border-radius: 6px;
  font-size: 14px;
}

.ant-card-bordered {
  .ant-card-head {
    border: transparent;
  }
  .ant-card-head-title {
    padding: 24px 0;
    .header__left {
      display: flex;
      align-items: flex-start;
      min-width: calc(100% - 250px);
      flex-direction: column;
      flex-direction: row;
    }
  }
  .ant-card-body {
    padding: 0px 24px;
  }
}
.all-cats {
  .ant-card-bordered {
    .ant-card-body {
      padding: 0px 40px;
    }
  }
  .dashboard__template__item {
    height: 220px;
  }
  @media (min-width: 1280px) {
    .ant-col-xl-6 {
      display: block;
      -webkit-box-sizing: border-box;
      box-sizing: border-box;
      width: 33.33333333%;
    }
  }
  @media (min-width: 1290px) {
    .ant-col-xl-6 {
      display: block;
      -webkit-box-sizing: border-box;
      box-sizing: border-box;
      width: 25%;
    }
  }
}

.makeStyles-card-3 {
  margin-left: 0% !important;
}
.MuiCardContent-root {
  padding: 0px !important;
}
.dashboard.header h2,
.dashboard__header h2 {
  font-weight: 500;
  text-transform: capitalize;
  font-size: 24px;
  span {
    font-weight: 400;
  }
}
.show-cat-list {
  display: block;
}
.hide-cat-list {
  display: none;
}
.data-main-container {
  background: $white;
  box-shadow: 8px 8px 20px #f9f9f9;
  border-radius: 4px;
  margin: 20px;
  padding: 20px;
  min-height: calc(100vh - 90px);
  h4 {
    font-size: 18px !important;
    padding-bottom: 15px !important;
    padding-top: 10px !important;
    display: inline-block !important;
    white-space: nowrap !important;
  }
  .ant-card-bordered {
    border: 0;
    background: #f7f9fa;
    box-shadow: none;
    @include rounded-corners(4px);
  }
  .ant-btn-primary[disabled] {
    background: #d6dce5;
    border-radius: 6px;
    font-size: 14px;
    color: $white;
    padding: 8px 35px;
    height: auto;
    border-color: #d6dce5;
  }
  .dashboard__template__select__button {
    background: #245ea4;
    border-radius: 6px;
    font-size: 14px;
    color: $white;
    padding: 8px 35px;
    height: auto;
    border-color: #245ea4;
    box-shadow: none;
  }
}
.ant-btn {
  &.btn-link {
    font-weight: normal;
    color: #245ea4;
  }
}
input[type='text'],
input[type='password'],
input[type='number'],
textarea,
.txtBox {
  background: $white;
  border: 1px solid #d6dce5;
  box-sizing: border-box;
  box-shadow: inset 0px 1px 2px #e0e0e0;
  border-radius: 6px;
  height: 40px;
  padding: 15px;
  font-size: 14px;
  color: #8c93ab;
}
.search-container {
  background-color: #fff;
  position: relative;
  margin-bottom: 24px;
  text-align: right;
  z-index: 9;
  input.search-input {
    display: inline-block;
    box-sizing: border-box;
    padding: 10px;
    width: 340px;
  }
}

.cat-nav {
  padding: 0;
  margin: 0;
}
.cat-nav li {
  display: flex;
  justify-content: space-between;
  list-style: none;
  padding: 10px 10px 10px 0px;
  margin: 0px;
  cursor: pointer;
}
.cat-nav li .cat-count {
  background: #f7f9fa;
  border-radius: 99px;
  padding: 2px 10px;
  font-size: 12px;
}
.cat-nav li.active span,
.cat-nav li.active .cat-count {
  color: #245ea4;
  font-weight: 700;
}
.anticon-caret-up,
.anticon-caret-down {
  font-size: 14px !important;

  &.on {
    color: var(--primary-color) !important;
  }
}

.ant-drawer-right {
  .ant-drawer-title {
    .ant-btn-link {
      &:hover {
        background: transparent;
        border: transparent;
        i {
          svg {
            path {
              fill: #245ea4;
            }
          }
        }
      }
    }
  }
}

.ant-drawer.ant-drawer-open {
  .ant-drawer-mask {
    background-color: rgba(255, 255, 255, 0);
    opacity: 0;
  }
}
// TODO : Integration form alignment fix (google ads)
.custom_style_handler {
  .ant-form-item-required {
    padding-left: 10px;
    position: relative;
  }
  .ant-form-item-required::before {
    left: 0;
    top: calc(50% - 11px);
    margin-left: 2px;
  }
  .ant-form-item-label {
    text-align: left;
  }
}
.ant-form-item-required::before {
  display: inline-block;
  margin-right: 4px;
  color: #f5222d;
  font-size: 14px;
  line-height: 1;
  content: '*';
  margin-left: 100%;
  position: absolute;
  top: 30%;
}
.connect-disconnect {
  table {
    tr {
      td {
        border-bottom: 0px solid #e8e8e8;
        border-top: 1px solid #e8e8e8;
        &:first-child {
          max-width: 60px;
          width: 60px;
        }
        &:nth-child(2) {
          font-weight: 700;
          font-size: 14px;
        }
        &:last-child {
          max-width: 200px;
          text-align: right;
        }
      }
    }
  }
}
.ant-select-selection {
  font-size: 13px !important;
  font-weight: 500 !important;
}
.ant-select-dropdown {
  background: $white;
  box-shadow: 0px 0px 10px #d6dce5;
  border-radius: 4px;
  border: none !important;
  min-width: 85px !important;
}
.ant-select-dropdown-menu-item:hover:not(.ant-select-dropdown-menu-item-disabled) {
  background: #f7f9fa !important;
}

.ant-divider {
  background: #4d5a74 !important;

  &.with-lightColor {
    background: #dddfe5 !important;
  }
}
.c-btnLink {
  border: 0 !important;
  background-color: transparent !important;
  cursor: pointer;
  display: inline-block;
  padding: 3px 0 !important;
  color: #2e73c6 !important;
  font-size: 14px;
  font-weight: 500;
  line-height: 18px;
  outline: none;
}
.c-btn {
  border: 0 !important;
  background-color: #2e73c6 !important;
  cursor: pointer;
  display: inline-block;
  padding: 8px 16px;
  color: $white !important;
  font-size: 14px;
  font-weight: 600;
  line-height: 17px;
  outline: none;
  width: auto !important;
  text-decoration: none !important;
  @include rounded-corners(6px);
}
.chart__builder {
  .chart__label {
    font-size: 12px;
    line-height: 18px;
    color: #8c93ab;
    font-weight: 400;
  }
  .ant-form-item {
    margin-bottom: 20px !important;
  }
  .chart__select {
    .ant-select-arrow {
      right: 25px;
    }
  }
  .publish-btn {
    width: auto;
    position: absolute;
    right: -20px;
    top: -65px;
  }
  .ant-tabs.ant-tabs-card {
    .ant-tabs-card-bar {
      .ant-tabs-tab {
        border: 1px solid #d6dce5 !important;
        padding: 5px 20px !important;
        margin-right: 0px !important;
        border-radius: 0px 0px 0 0 !important;
        background: $white;
        color: #4d5a74 !important;
        i {
          margin-right: 0px !important;
          svg {
            height: 16px !important;
            width: 16px !important;
          }
        }
        &:first-child {
          border-radius: 4px 0px 0 0 !important;
          border-right: none !important;
        }
        &:last-child {
          border-radius: 0px 4px 0 0 !important;
          border-left: none !important;
        }
      }
      .ant-tabs-nav-container {
        height: 48px !important;
        .ant-tabs-tab {
          height: 48px !important;
        }
      }
      .ant-tabs-tab-active {
        background: #eef4fb !important;
        color: #245ea4 !important;
        border-bottom: none !important;
      }
      .ant-tabs-tab-disabled {
        color: rgba(0, 0, 0, 0.25) !important;
      }
    }
  }
  .ant-tabs-bar {
    border-bottom: 0px solid #d6dce5 !important;
  }
  .ant-tabs-card {
    .ant-tabs-top-content,
    .ant-tabs-bottom-content {
      border: 1px solid #d6dce5;
      @include rounded-corners(0px 4px 4px 4px);
    }
  }
  .ant-tabs .ant-tabs-top-content {
    min-height: 240px;
  }
}
.ant-modal-body {
  .ant-select-arrow {
    right: 25px;
  }
}

.editSection {
  position: relative;
  padding: 0;
  vertical-align: middle;
  &.no-border {
    border: 0;
    padding-left: 0;
    &::before {
      display: none;
    }
  }
  .editBtn {
    border: 0;
    background-color: transparent;
    cursor: pointer;
    display: inline-block;
    padding: 2px;
    color: #2e73c6;
    font-size: 14px;
    font-weight: 500;
    line-height: 18px;
    outline: none;
    &:hover {
      background-color: transparent;
      color: #2e73c6;
    }
  }
}
.c-btnlink {
  border: 0;
  background-color: transparent;
  cursor: pointer;
  display: inline-block;
  padding: 2px;
  color: #2e73c6;
  font-size: 12px;
  font-weight: 500;
  line-height: 18px;
  outline: none;
  &:hover {
    background-color: transparent;
    color: #2e73c6;
  }
}
.closeCompare {
  border: 0;
  background-color: transparent !important;
  display: inline-block;
  color: #8c93ab !important;
  .anticon {
    color: #8c93ab !important;
  }
}

.c-dateRangePicker {
  text-align: left !important;
  &.ant-calendar-picker {
    .ant-calendar-picker-input {
      background: #eef4fb;
      border: 1px solid #8c93ab;
      box-sizing: border-box;
      padding: 10px 2px;
      @include rounded-corners(6px);
      @include box-shadow(inset 0px 1px 2px #e0e0e0);
      .anticon {
        display: none;
      }
      .ant-calendar-range-picker-input {
        color: #4d5a74;
        font-weight: 600;
        width: 88px;
        font-size: 12px;
        line-height: 18px;
      }
    }
  }
}
.c-popoverGraph {
  display: block;

  .pg_info {
    color: var(--primary-color);
    display: block;
    font-size: 12px;
    font-weight: 600;
    line-height: 18px;
    margin-bottom: 12px;
    position: relative;
    .pg_title {
      display: block;
      color: #8c93ab;
      margin-bottom: 5px;
      font-size: 12px;
      line-height: 18px;
      font-weight: 500;
    }
  }
  .pg_change {
    display: inline-block;
    margin-left: 12px;
  }
  .info__popup__datasource {
    display: block;
    position: relative;
    padding: 0 0 0 26px;
    min-height: 16px;
    margin-bottom: 8px;
    .data__sources__image {
      position: absolute;
      top: 0;
      left: 0;
      width: 26px;
      img {
        display: block;
      }
    }
    .data__sources__title {
      color: #4d5a74;
      display: block;
      font-size: 12px;
      font-weight: 600;
      line-height: 18px;
    }
  }
}

.ant-popover {
  background-color: #ffffff;
  padding: 0 !important;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.15);
  border-radius: 14px;
  .ant-popover-inner-content {
    box-sizing: border-box;
    padding: 16px !important;
    max-width: 310px !important;
  }
  .ant-popover-arrow {
    opacity: 0 !important;
  }
}
/* ---- Added this class (c-tableSortable) to change table default ant design styles ---- */
.c-tableSortable {
  table {
    border: 0;
    th {
      background-color: #f7f9fa !important;
    }
    td {
      padding: 17px !important;
      color: #4d5a74;
    }
    th,
    td {
      border: 0 !important;
      border-bottom: 1px solid #e9ecf7 !important;
      font-size: 14px;
      font-weight: 600;
      line-height: 18px;
      padding: 17px !important;
    }
  }

  .ant-table-column-has-sorters {
    .ant-table-column-sorter-inner {
      top: -2px;
      position: relative;
      height: auto !important;
      opacity: 0 !important;
      @include transition(opacity ease 0.2s);
    }
    &:hover,
    &.ant-table-column-sort {
      .ant-table-column-sorter-inner {
        opacity: 1 !important;
      }
    }
  }
  .ant-table-column-sort {
    background-color: #fafafa;
  }
}
.ant-select-selection__clear {
  right: -24px !important;
  opacity: 1 !important;
}
.btn_actions__download {
  .anticon-loading {
    color: #245ea4;
    left: -10px;
    position: relative;
  }
}

// -- fix for user analysis sunburst chart height
.c-userAnalyis #root1 .ant-card-body {
  min-height: 170px !important;
}

.btnLogout {
  display: block;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: 0.1px;
  color: #245ea4;
  padding: 5px 10px;
}

// -------------------------------------
// --------- BUTTON COMPONENT ----------
// -------------------------------------

/* ---- Utility Classes ---- */
.m0 {
  margin: 0 !important;
}
.mr-10 {
  margin-right: 10px;
}
.mt-5 {
  margin-top: 5px;
}
.cursor-pointer {
  cursor: pointer;
}
.text-uppercase {
  text-transform: uppercase;
}
.no-border {
  border: 0 !important;
}
.showScrollbar {
  overflow: auto;
}

// --- ***** Compare mode dashed lines
.compare__true .msspline g[class*='common-elems-group']:nth-child(7) path,
.compare__true .msspline g[class*='common-elems-group']:nth-child(10) path {
  stroke-dasharray: 5, 4 !important;
  opacity: 0.6;
}
.compare__true
  .mssplineMulti
  g[class*='vcanvas-line-plot']:first-child
  g[class*='common-elems-group']:nth-child(4)
  path {
  stroke-dasharray: 5, 4 !important;
  opacity: 0.6;
}
.compare__true
  .mssplineMulti
  g[class*='vcanvas-line-plot']:last-child
  g[class*='common-elems-group']:nth-child(4)
  path {
  stroke-dasharray: 5, 4 !important;
  opacity: 0.6;
}

.compare__true .msspline.lg2 g[class*='vcanvas-line-plot'] g[class*='common-elems-group']:nth-child(4) path {
  stroke-dasharray: 5, 4 !important;
  opacity: 0.6;
  stroke: rgb(242, 129, 109) !important;
}
.compare__true .msspline.lg2 g[class*='vcanvas-line-plot'] g[class*='plot-group']:nth-child(5) path {
  stroke: rgb(242, 129, 109) !important;
}

.compare__true .area__graph g[class*='vcanvas-area-plot']:last-child g[class*='common-elems-group']:nth-child(4) path {
  stroke-dasharray: 5, 4 !important;
  opacity: 0.6;
}
.area__graph g[class*='vcanvas-area-plot']:last-child g[class*='common-elems-group'] path {
  fill: transparent;
}
.area__graph g[class*='vcanvas-area-plot']:last-child g[class*='plot-group'] path {
  stroke: #7bca90 !important;
}

.compare__true .area__graph g[class*='vcanvas-area-plot']:last-child g[class*='common-elems-group']:nth-child(4) path {
  stroke-dasharray: 5, 4 !important;
  opacity: 0.6;
}

.topWidgets .msspline.lg2 g[class*='vcanvas-line-plot'] g[class*='common-elems-group']:nth-child(4) path {
  stroke-dasharray: 5, 4 !important;
  opacity: 0.6;
  stroke: rgb(255, 102, 54) !important;
}
.topWidgets .msspline.lg2 g[class*='vcanvas-line-plot'] g[class*='plot-group']:nth-child(5) path {
  stroke: rgb(255, 102, 54) !important;
}
g[class*='legendGroup'] g[class*='item'] text {
  max-width: 50% !important;
  width: 50% !important;
}

.ant-btn.ant-btn-link.avatar_btn {
  color: #4d5a74 !important;
  font-size: 14px !important;
  font-weight: 500 !important;
  background: transparent !important;
  padding: 2px 6px;
  &:hover {
    background: transparent !important;
  }
}
.user__menu {
  position: relative;
  max-height: 400px;
  overflow-y: auto;
  .ant-dropdown-menu-item-group-title {
    display: none !important;
  }
}

.c-inputWrapper {
  display: inline-block;
  position: relative;
  .txtSearch {
    box-sizing: border-box;
    width: 240px;
  }
  .btnClear {
    cursor: pointer;
    position: absolute;
    top: 14px;
    right: 6px;
    height: 14px;
    width: 14px;
    border: 0;
    padding: 0;
    outline: none;
    background-color: transparent;
    @include rounded-corners(10px);
    .anticon {
      display: inline-block;
      font-size: 14px;
      position: relative;
      top: -4px;
      right: 0;
    }
  }
}
.clear {
  clear: both !important;
}
.add_circle {
  background-color: transparent;
  border: 0;
  cursor: pointer;
  display: inline-block;
  font-size: 18px;
  line-height: 18px;
  outline: none !important;
}

.no-hover {
  &:hover {
    cursor: text;
    background-color: transparent;
    color: #4d5a74 !important;
  }
}

.chart-tooltip {
  padding: 4px;
  padding-right: 16px;
  .chart-tooltip-compare-value {
    font-size: 16px;
  }
  .chart-tooltip-label-value-wrapper {
    margin-bottom: 4px;
  }
  .chart-tooltip-label-wrapper {
    display: flex;
    align-items: center;
    .chart-tooltip-label-color-box {
      width: 10px;
      height: 10px;
      margin-right: 4px;
    }
    .chart-tooltip-label {
      font-size: 12px;
    }
  }
  .chart-tooltip-value {
    font-size: 14px;
  }
}
.create_segment_heading {
  margin-top: -160px;
}

// style for Growth advisary sidebar links
.sidebar__mid {
  border-top: 1px solid #e9ecf7;
  border-bottom: 1px solid #e9ecf7;
  display: flex;
  flex-direction: column;
  margin-left: -1px;
  width: 100px !important;

  &.custom_menus {
    min-height: 240px;
    .linkNav {
      img {
        height: 21px;
        width: 21px;
      }
    }
  }
  &.noBorder {
    border: 0;
  }
  .linkNav {
    border: 0;
    background-color: transparent !important;
    cursor: pointer;
    display: block;
    position: relative;
    text-align: center;
    margin: 0 !important;
    padding: 6px 8px !important;
    width: 100%;
    height: auto !important;
    outline: none;
    text-decoration: none !important;

    &::after {
      display: none;
    }

    .linkTitle {
      display: block;
      color: var(--primary-color);
      font: 600 10px/12px var(--primary-font);
      text-transform: uppercase;
      letter-spacing: 0.4px;
      margin-top: 4px;
      text-decoration: none !important;
    }
    .badge {
      background-color: var(--alert-success);
      font-size: 10px;
      color: var(--white);
      padding: 2px 4px;
    }

    .hoverContainer {
      display: grid;
      place-content: center;
      height: 32px;
      width: 32px;
      background-color: transparent;
      position: relative;
      z-index: 2;
      margin: 0 auto;
      @include rounded-corners(3px);
      @include transition(background-color ease 0.3s);

      &.custome-icon {
        &::before {
          content: '';
          position: absolute;
          height: 14px;
          width: 14px;
          top: 6px;
          left: 9px;
          background: rgba(0, 0, 0, 0);
          z-index: 4;
          @include rounded-corners(100px);
          @include pulse-animation;
        }
      }
    }
    &:hover {
      .hoverContainer {
        background-color: #e6f7ff;
      }
      img,
      .anticon {
        filter: grayscale(0%);
      }
    }
    &.active {
      &::before {
        background-color: $primary;
        content: '';
        position: absolute;
        top: 11px;
        left: 0;
        height: 32px;
        width: 3px;
        @include rounded-corners(0 5px 5px 0);
      }
      .hoverContainer {
        background-color: #e6f7ff;
      }
      img,
      .anticon {
        filter: grayscale(0%);
      }
    }
    img,
    .anticon {
      display: inline-block;
      height: 20px;
      width: 20px;
      opacity: 0.8;
      margin: 0;
      position: relative;
      z-index: 3;
      filter: grayscale(100%);
    }
    .anticon {
      display: block;
      font-size: 21px;
      line-height: 28px;
    }
  }
}

.migrate_notif_wrapper {
  .description {
    text-align: center;
    font-size: 13px;
    line-height: 18px;
    background: #d5e4c5;
    border: 1px solid #b7eb8f;
    padding: 12px;

    .first_line {
      color: #53823c;
      font-weight: 600;
      display: block;
      font-size: 15px;
      line-height: 22px;
      margin-bottom: 4px;
    }

    .second_line {
      color: #777;
      font-weight: 600;
      display: block;
      font-size: 13px;
    }
  }
}

// -- mobile css fixes for growth advisory

@media all and (max-device-width: 768px) {
  .slide-text {
    h2 {
      font-size: 32px !important;
      line-height: 42px !important;
    }
  }
  .auth__page__wrapper .auth__page__backgroud__wrapper {
    min-height: 100% !important;
  }
}

// --------- Login Container
.c-loginPageWrapper {
  background: url('../src/assets/background/login.jpg') left center no-repeat #010022;
  position: relative;
  display: flex;
  min-height: 100vh;
  align-items: center;
  .login_info {
    display: block;
    .login_info__logo {
      display: block;
      margin-bottom: 24px;
      img {
        display: block;
        max-height: 80px;
      }
    }
    .login_info__title {
      display: block;
      position: relative;
      color: var(--white);
      font-size: 58px;
      font-weight: 900;
      line-height: 68px;
      letter-spacing: 0.7px;
      text-align: left !important;
      position: relative;
      margin-bottom: 40px;
      &::after {
        content: '';
        position: absolute;
        height: 4px;
        width: 135px;
        background-color: #e8913b;
        left: 0;
        bottom: -20px;
      }
    }
    .login_external_link {
      display: block;
      position: relative;
      color: var(--white);
      font-size: 18px;
      line-height: 24px;
      font-weight: 600;

      .link {
        display: inline-block;
        margin-left: 10px;
        color: var(--white);
        text-decoration: underline !important;
      }
    }
  }
}
.c-loginContainer {
  background: var(--white);
  padding: 24px 20px;
  @include rounded-corners(8px);
  .growth__logo {
    display: block;
    padding: 0;
    margin-bottom: 16px;
    text-align: center;
    img {
      display: inline-block;
      max-height: 64px;
    }
  }
}
@media all and (max-device-width: 768px) {
  .c-loginPageWrapper {
    padding: 50px 0;
    min-height: calc(100vh - 100px);
    .login_info {
      margin-bottom: 50px;
      .login_info__logo {
        margin-bottom: 20px;
        img {
          max-height: 40px;
        }
      }
      .login_info__title {
        font-size: 28px;
        line-height: 38px;
      }
    }
  }
  .c-loginContainer {
    padding: 40px 20px;
  }
}

.auth__form__button {
  width: 100%;
  padding: 20px 0 !important;
  line-height: 0px;
  border-radius: 6px;
  margin: 20px 0;
  background-color: #245ea4;
  border-color: #245ea4;
  height: 56px;
  font-size: 14px;
  font-weight: 700;
}
.auth__error {
  display: block;
  text-align: left;
  color: $danger;
  font-size: 1em;
  margin-bottom: 10px;
  background: url('../src/assets/icons/alert.svg') 17px 10px no-repeat #ffeded;
  border-radius: 2px;
  padding: 10px 20px 10px 50px;
}
.form__forgot {
  float: right;
}
.connectedAccountCount {
  padding-top: 20px;
}
.isLink {
  color: $primary !important;
  background-color: transparent !important;
}

.dataSource {
  display: inline-block;
  position: relative;
  width: 300px;

  &_info {
    display: block;
    position: relative;
  }

  &.showInfo {
    padding-left: 32px;
    .dataSource_image {
      color: #fa8c16;
      position: absolute;
      top: 50%;
      left: 0;
      font-size: 22px;
      margin-top: -12px;
      &.success {
        color: var(--alert-success);
      }
      &.fail {
        color: var(--alert-fail);
      }
    }
  }
}

.spinner_center {
  display: flex;
  justify-content: center;
  height: 100vh;
  align-items: center;
}
.segmentOverlap {
  table {
    thead {
      th {
        &:first-child {
          .ant-checkbox-wrapper {
            display: none;
          }
        }
        &:nth-child(1) {
          text-align: left !important;
        }
      }
    }
  }
}

.c-noInsights {
  position: absolute;
  height: 220px;
  width: 40%;
  top: 50%;
  left: 50%;
  margin: -110px 0 0 -20%;
  .ni_image {
    background: var(--white) url('./assets/images/no-insights.png') center center no-repeat;
    background-size: 140px 140px;
    display: block;
    margin: 0 auto 21px;
    height: 150px;
    width: 150px;
  }
  .ni_heading,
  .ni_subheading {
    display: block;
    font-weight: 600;
    font-size: 18px;
    line-height: 24px;
    text-align: center;
    margin-bottom: 16px;
    color: var(--fontColor-light);
  }
  .ni_subheading {
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    margin-bottom: 0;
  }
}
@media all and (max-device-width: 768px) {
  .c-noInsights {
    height: 240px;
    width: 80%;
    margin: -120px 0 0 -40%;
    .ni_image {
      background-size: 90px 90px;
      height: 90px;
      width: 90px;
    }
  }
}
.dimentionSearchQuery {
  text-decoration: none;
  background-color: transparent;
  cursor: text;
  font-weight: 600;
  display: inline-block;
  border: 0;
  padding: 0;
  outline: none !important;
}
.c-fullpageLoader {
  background-color: var(--white);
  display: grid;
  height: 100%;
  left: 0;
  place-items: center;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 99;
}

.isFullPage {
  height: calc(100% - 60px);
}
.isAtCenter {
  display: grid;
  place-items: center;
}
// --- Temporary code till attribution page is ready
.attributionModal {
  .ant-modal-content {
    background-color: transparent;
  }
  .ant-modal-body {
    padding: 0;
    .close {
      background-color: var(--white);
      display: grid;
      place-items: center;
      position: absolute;
      font-size: 10px;
      top: 10px;
      right: 10px;
      height: 26px;
      width: 26px;
      z-index: 99;
      @include rounded-corners(100px);
    }
  }
}

.compareDropdownWrapper {
  background: #fafafa;
  border: 1px solid #e8e8e8;
  display: flex;
  flex-direction: row;
  gap: 0;
  margin-bottom: -1px;
  @include rounded-corners(4px 4px 0 0);
  .wrapper_section {
    border-right: 1px solid #e8e8e8;
    padding: 12px;
    flex-basis: 30%;
    &:last-child {
      border: 0;
    }
    &.custom {
      flex-basis: 40%;
    }
  }
}

iframe {
  display: none !important;
}

span[role='button'] {
  color: var(--primary-color);
  cursor: pointer;
}

.react-grid-item {
  height: 564px !important;
}
