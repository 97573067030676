// Import mixins
@import 'assets/styles/mixins.scss';

/////////////////////////////////////////////////////////////////////////////////////////
/*  ANT DESIGN NOTIFICATION */

.ant-notification {
  width: calc(100vw - 48px);
  .ant-notification-notice {
    max-width: 380px;
  }
}

.ant-message-success {
  span {
    margin-left: 20px;
  }
  .anticon-check-circle {
    svg {
      display: none;
    }
    &::after {
      position: absolute;
      width: 20px;
      height: 20px;
      top: -15px;
      content: '';
      background: url('../../../images/common-icons/check-circle-success.svg') left top no-repeat;
    }
  }
}
.ant-message-warning {
  background: #fff2d7;
  border: 1px solid #fee2a3;
  padding: 10px;
  color: #e8913b;
  span {
    margin-left: 20px;
  }
  .anticon-exclamation-circle {
    svg {
      display: none;
    }
    &::after {
      position: absolute;
      width: 20px;
      height: 20px;
      top: -20px;
      content: '';
      background: url('../../../images/common-icons/exclamation-circle.svg') left top no-repeat;
    }
  }
}
.ant-message-info {
  background-color: #e6f7ff;
  border: 1px solid #91d5ff;
  padding: 10px;
  color: #245ea4;
  span {
    margin-left: 20px;
  }
  .anticon-info-circle {
    svg {
      display: none;
    }
    &::after {
      position: absolute;
      width: 20px;
      height: 20px;
      top: -20px;
      content: '';
      background: url('../../../images/common-icons/primary-info.svg') left top no-repeat;
    }
  }
}
