@import '../../assets/styles/scss/styleGuide/variables';

.dueContainer {
  display: flex;
  flex-direction: column;
  width: 960px;
  margin: 24px auto;
  .dc {
    &_header {
      text-align: center;
    }
  }
}

.dc_form {
  .ant-form-item-label {
    line-height: normal;
    text-align: left;
    margin-bottom: 4px;
  }
  .ant-divider-horizontal {
    &.ant-divider {
      background: transparent !important;
      border-color: var(--fontColor-extra-light) !important;
      margin: 8px 0 12px;
    }
  }
}

.imageContainer {
  img {
    width: 80%;
  }
}

.sectionOverlay {
  position: relative;
  .overlayMessage {
    display: grid;
    place-items: center;
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    z-index: 5;
  }
  &.active {
    &::before {
      background-color: RGBA(255, 255, 255, 0.6);
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      z-index: 4;
    }
  }
}
