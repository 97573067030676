@import '../../assets/styles/scss/styleGuide/variables';

.c-pageHeader {
  align-items: center;
  background: var(--white);
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  min-height: 70px;
  padding: 14px 16px 16px 16px;
  position: fixed;
  width: calc(100% - 100px);
  z-index: 99;
  @include box-shadow(inset 0px -1px 0px #e9ecf7);
  &.isFlexibleHeader {
    box-sizing: border-box;
    width: calc(100% - calc(var(--sidebar-width) + 110px));
  }
  .header {
    &_left {
      align-items: center;
      display: flex;
      flex-direction: row;
      gap: 4px;
    }
    &_right {
      flex-grow: 1;
      text-align: right;
      justify-content: flex-end;
      ul {
        justify-content: right;
        align-items: center;
        display: flex;
        gap: 12px;
        flex-direction: row;
        list-style-type: none;
        margin: 0;
        padding: 0;
      }
    }
  }

  &.forMobile {
    padding: 10px;
    width: 100%;
    top: 0;
    margin: 0;
    min-height: 58px;

    .heading {
      font-size: 1.2rem;
      line-height: 1.3rem;
      margin-right: 0;
    }
  }
  &.isFullWidth {
    width: 100%;
  }
}

.heading,
.subHeading {
  font-size: 20px;
  line-height: 26px;
  color: var(--primary-color);
  font-weight: 600;
  margin: 0;
  margin-right: 10px;
  vertical-align: middle;
}
.subHeading {
  font-size: 16px;
  line-height: 20px;
  position: relative;
  padding-left: 12px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 300px;
  &::before {
    background-color: #d6dce5;
    content: '';
    position: absolute;
    top: calc(50% - 8px);
    left: 0;
    height: 16px;
    width: 2px;
  }
}

.mobileMenuHandler {
  background-color: transparent;
  display: grid;
  place-items: center;
  border: 0;
  outline: 0;
  height: 36px;
  width: 36px;
  img {
    width: 22px;
  }
}

// -- sidebar
.c-mobileSidebar {
  .ant-drawer-header {
    display: none;
  }
  &.ant-drawer-open {
    .ant-drawer-mask {
      background-color: rgba(0, 0, 0, 0.45) !important;
      opacity: 1 !important;
    }
    // -- Transition effect
    .c-transitionWrapper {
      li {
        animation: fadeInRight 0.25s ease forwards;
        $count: 0.1;
        @for $i from 1 through 10 {
          &:nth-of-type(#{$i}) {
            animation-delay: ($count + 0.06) + s;
          }
          $count: $count + 0.06;
        }
      }
    }
  }

  .storeSelect {
    display: block;
    .storeSelect_lbl {
      color: var(--primary-color);
      display: block;
      font-size: 12px;
      font-weight: 600;
      margin-bottom: 8px;
    }
  }
  .storeSelect_options {
    display: block;
    .avatar__menu {
      &.avatar_btn {
        background: #effaff !important;
      }
      display: block;
      padding: 8px 30px 8px 12px;
      text-align: left;
      width: 100%;
      position: relative;
      @include rounded-corners(3px);
      .title {
        display: inline-block;
        font-size: 14px;
        font-weight: 600;
      }
      .anticon {
        position: absolute;
        top: 16px;
        right: 10px;
        font-size: 14px;
        font-weight: 600;
      }
    }
  }
  .ant-btn.ant-btn-link.avatar_btn:hover {
    background: #f7f9fa !important;
  }
  .ant-drawer-close {
    padding: 10px;
    .anticon {
      position: relative;
      top: 0;
      right: -11px;
    }
  }
}

// -- menu list
.c-transitionWrapper {
  .c-menuMobile {
    display: block;
    padding: 16px 0;
    ul {
      display: block;
      padding: 0;
      margin: 0;
      list-style-type: none;
      li {
        display: block;
        position: relative;
        padding: 0 24px;
        opacity: 0;
        margin-bottom: 8px;
        &.hasBorder {
          border-bottom: 1px solid #f9f9f9;
          margin-bottom: 12px;
          padding-bottom: 12px;
        }
        &.title {
          color: var(--primary-color);
          display: block;
          font-size: 12px;
          font-weight: 600;
          padding: 0 24px;
          margin-bottom: 8px;
        }
        .menuLink {
          background-color: transparent;
          border: 0;
          display: block;
          padding: 8px 16px 8px 0;
          position: relative;
          text-align: left;
          text-decoration: none;
          width: 100%;
          .menu_name {
            color: var(--black);
            display: block;
            font-size: 14px;
            line-height: 22px;
            font-weight: 600;
            letter-spacing: 1px;
          }
          .menu_icon {
            top: 12px;
            left: 8px;
            position: absolute;
            height: 26px;
            width: 26px;
            text-align: center;
            .menu_icon_img {
              height: 20px;
              width: 20px;
              &.lg {
                height: 24px;
                width: 24px;
              }
            }
            .anticon {
              color: #7cc0dc;
              font-size: 18px;
              display: inline-block;
              vertical-align: middle;
              line-height: 26px;
            }
          }
          &.light {
            .menu_name {
              font-weight: 400;
            }
            .anticon {
              color: #ccc;
            }
          }
        }
      }
    }
  }
}

@keyframes fadeInRight {
  0% {
    opacity: 0;
    left: 29%;
  }

  100% {
    opacity: 1;
    left: 0;
  }
}

.headerLink {
  display: block;
  text-decoration: none !important;
}

// -- sidebar inner structure
.c-headMobile {
  border-bottom: 1px solid #f9f9f9;
  display: block;
  text-align: center;
  padding: 18px 24px;
  .hm_logo {
    display: block;
    height: 160px;
    width: 160px;
    padding: 42px;
    text-align: center;
    margin: 0 auto 10px;
    /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#cae9f7+17,cae9f7+19,cae9f7+19,ffffff+73&1+17,0+73 */
    background: -moz-radial-gradient(
      center,
      ellipse cover,
      rgba(202, 233, 247, 1) 17%,
      rgba(202, 233, 247, 0.96) 19%,
      rgba(255, 255, 255, 0) 73%
    ); /* FF3.6-15 */
    background: -webkit-radial-gradient(
      center,
      ellipse cover,
      rgba(202, 233, 247, 1) 17%,
      rgba(202, 233, 247, 0.96) 19%,
      rgba(255, 255, 255, 0) 73%
    ); /* Chrome10-25,Safari5.1-6 */
    background: radial-gradient(
      ellipse at center,
      rgba(202, 233, 247, 1) 17%,
      rgba(202, 233, 247, 0.96) 19%,
      rgba(255, 255, 255, 0) 73%
    ); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#cae9f7', endColorstr='#00ffffff',GradientType=1 ); /* IE6-9 fallback on horizontal gradient */

    .logo {
      max-height: 74px;
      display: inline-block;
    }
  }
  .hm_title {
    display: block;
    text-align: center;
    .mainTitle {
      color: var(--primary-color);
      display: block;
      font-size: 16px;
      font-weight: 600;
      line-height: 22px;
      margin-bottom: 4px;
    }
    .subTitle {
      color: var(--black);
      display: block;
      font-size: 20px;
      font-weight: 600;
      line-height: 30px;
      margin-bottom: 0;
    }
  }
  .closeSidebar {
    border: 0;
    background-color: #f9f9f9;
    position: absolute;
    top: 12px;
    right: 12px;
    height: 30px;
    width: 30px;
    padding: 4px 7px;
    @include rounded-corners(50px);
    .anticon {
      color: #777;
      font-size: 14px;
      display: inline-block;
    }
  }
}

.c-dropdown {
  .ant-select-selection {
    padding: 0;
    @include box-shadow(none);
  }
}
