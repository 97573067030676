// Import mixins
@import 'assets/styles/mixins.scss';

/////////////////////////////////////////////////////////////////////////////////////////
/*  ANT DESIGN PROGRESS */

.ant-progress-text {
  white-space: nowrap;
  top:55%;
}
