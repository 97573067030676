@import 'assets/styles/mixins';

.ant-drawer-header {
  background: #fff;
  height: 84px;
  display: flex;
  align-items: center;

  .ant-drawer-title {
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 24px;
    color: #4d5a74;
  }
  .ant-drawer-close {
    color: #8c93ab;
    display: flex;
    align-items: center;
    height: 100%;
  }
}

.datasource_drawer {
  .ant-drawer-body {
    padding: 24px;
  }
  .ant-drawer-close {
    width: 46px;
    &:focus {
      outline: none;
    }
  }
  .form__cancel__button {
    border: transparent;
  }
  .ant-form-item-label {
    width: 100%;
    display: block;
  }
  .ant-form-item-control-wrapper {
    width: 100%;
  }
}
