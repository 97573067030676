$colors: (
  primary: #005dff,
  primary-light: lighten(#005dff, 40%),
  primary-dark: darken(#005dff, 40%),
  accent: #fff6bb
);

@function color($color-name) {
  @return map-get($colors, $color-name);
}

html,
body {
  height: 100%;
  font-size: 14px;
  color: #4d5a74;
}

body {
  //   background-color: color(primary);
  text-align: left;
  background: #f7f9fa;
}
