// Import mixins
@import 'assets/styles/mixins.scss';

/////////////////////////////////////////////////////////////////////////////////////////
/*  ANT DESIGN TABS */

.ant-tabs-vertical.ant-tabs-card .ant-tabs-card-bar.ant-tabs-left-bar .ant-tabs-tab {
  border: none;
  background-color: #ffffff;
  color: #4D5A74;
  font-weight: 400;
  padding-right: 10px;
  padding-left: 10px;
  margin-right: -1px;
}

.ant-tabs-vertical.ant-tabs-card.ant-tabs-left .ant-tabs-card-bar.ant-tabs-left-bar .ant-tabs-tab-active {
  background: #F7F9FA;
  border-radius: 4px;
  color: $primary;
  font-size: 14px;
  font-weight: 700;
  padding-right: 10px;
  padding-left: 10px;
  margin-right: -1px;
}
.filter_wrapper .ant-tabs .ant-tabs-left-bar{
  padding-top: 50px;
}
.ant-tabs .ant-tabs-left-bar {
  float: left;
  margin-right: -1px;
  margin-bottom: 0;
  min-width: 225px;
  padding-right: 20px;
}
.filter_wrapper .ant-tabs .ant-tabs-left-content{
  border-left: 0px;
  padding-top: 50px;
}
.ant-tabs-vertical.ant-tabs-card.ant-tabs-left .ant-badge-count{
  z-index: 10;
  min-width: 22px;
  height: 20px;
  padding: 0 6px;
  color: #4D5A74;
  font-weight: normal;
  font-size: 12px;
  line-height: 20px;
  white-space: nowrap;
  text-align: center;
  border-radius: 10px;
  -webkit-box-shadow: 0 0 0 0px #fff;
  box-shadow: 0 0 0 0px #fff;
  background: #EEF4FB;
  border-radius: 99px;
  font-weight: 400;
}
.ant-tabs-card-bar.ant-tabs-left-bar .ant-tabs-tab-active .ant-badge-count{
  z-index: 10;
  min-width: 22px;
  height: 20px;
  padding: 0 6px;
  color: $primary;
  font-weight: normal;
  font-size: 12px;
  line-height: 20px;
  white-space: nowrap;
  text-align: center;
  border-radius: 10px;
  -webkit-box-shadow: 0 0 0 0px #fff;
  box-shadow: 0 0 0 0px #fff;
  background: #EEF4FB;
  border-radius: 99px;
  font-weight: 700;
}
