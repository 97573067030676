// Import mixins
@import 'assets/styles/mixins.scss';

/////////////////////////////////////////////////////////////////////////////////////////
/*  ANT DESIGN BUTTON */

.ant-btn {
  border-color: $primary;
  color: $white;
  background: $primary;
  font-size: 14px;
  padding: 8px 35px;
  height: auto;
  box-shadow: none;
  text-shadow: none;
  font-weight: 700;
  &.ant-btn-link {
    background: transparent;
    border: transparent;
    padding: 5px 10px;
    &:hover {
      i {
        color: $white;
      }
    }
  }
}

.ant-btn-primary {
  background: $primary;
  border-color: $primary;
}
.form__cancel__button {
  background: transparent;
  border-color: $primary;
  color: $primary;
}
.ant-btn:hover,
.ant-btn:focus {
  color: $white;
  background: $primary;
  border-color: $primary;
}
