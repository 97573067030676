// Import mixins
@import 'assets/styles/mixins.scss';

/////////////////////////////////////////////////////////////////////////////////////////
/*  ANT DESIGN MODAL */

.ant-modal-close {
  outline: none !important;
}
// .ant-modal-footer .ant-btn:first-child{
//   background: #ffffff;
//   color: #245EA4;
// }
