// Import mixins
@import 'assets/styles/mixins.scss';

/////////////////////////////////////////////////////////////////////////////////////////
/*  ANT DESIGN MENU */

.ant-menu-item {
  outline: none !important;
  & > a {
    color: #595959;
  }
}

.ant-menu-dark.ant-menu-submenu-popup {
  .ant-menu-item-divider {
    opacity: 0.2;
  }
}

.ant-menu-inline {
  border: none;
}

.ant-menu-item-group-title {
  font-weight: 500;
}

.ant-menu-inline-collapsed {
  & > .ant-menu-item {
    .anticon {
      font-size: 25px;
    }
  }
}
