.c-footer {
  display: block;
  padding: 4px;
  margin: 8px 0;
  text-align: center;

  .footer {
    &_text {
      color: #777;
      display: inline-block;
      font-size: 12px;
      font-weight: 700;
      margin-right: 8px;
      vertical-align: middle;
    }
    &_img {
      display: inline-block;
      height: 34px;
      vertical-align: middle;
    }
  }
}
