// Import mixins
@import 'assets/styles/mixins.scss';

/////////////////////////////////////////////////////////////////////////////////////////
/*  ANT DESIGN SELECT */

.ant-select-dropdown {
  border: 1px solid #e9e9e9;
  animation-duration: 0s !important;
  z-index: 9999;
}

.ant-select-auto-complete {
  ol,
  ul,
  dl {
    margin: 0;
  }
}

.ant-select-selection {
  display: block;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  background-color: #fff;
  border: 1px solid #d9d9d9;
  border-top-width: 1.02px;
  outline: none;
  -webkit-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background: #ffffff;
  border: 1px solid #d6dce5;
  box-sizing: border-box;
  box-shadow: inset 0px 1px 2px #e0e0e0;
  border-radius: 6px;
  height: 40px;
  padding: 4px;
  font-size: 12px;
  font-weight: 500;
  color: #8c93ab;
  height: auto;
  height: auto !important;
  &:hover {
    border-color: $primary;
  }
}
