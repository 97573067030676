/*
*  SCSS TABLE OF CONTENTS
*
*  1.0 - Theme Variables
*  2.0 - SCSS functions
*  3.0 - SCSS Mixins
*  4.0 - Components
*      4.1 - Button
*      4.2 - Checkbox
*      4.3 - Radio Button
*      4.4 - Checkbox
*      4.5 - Textbox, Textarea
*      4.6 - Tags
*/

@import './variables';

/* -------------------------------------
      🍔 Components - Button
  ------------------------------------- */
.infoButton {
  border: 0;
  background-color: transparent;
  color: var(--fontColor);
  cursor: pointer;
  margin-left: 4px;
  padding: 2px;
  font-size: 14px;
  line-height: 14px;
}
.c-button {
  background-color: transparent;
  border: 0;
  color: var(--white);
  cursor: pointer;
  display: inline-block;
  font-size: 14px;
  font-weight: 600 !important;
  height: auto !important;
  line-height: 18px !important;
  text-decoration: none !important;
  letter-spacing: 0.1px;
  padding: 9px 16px !important;
  @include rounded-corners(6px);
  @include box-shadow(none);
  @include transition(all ease 0.3s);

  &.bold {
    font-weight: 700;
  }
  &.sm {
    height: 28px !important;
    line-height: 18px !important;
    letter-spacing: 0.1px;
    padding: 4px 14px !important;
  }

  &:disabled {
    cursor: not-allowed;
  }

  // - For Primary Button with background
  &.is-filled {
    border: 0;
    background-color: var(--primary-color);
    color: var(--white);
    &:hover,
    &:active,
    &:focus {
      background-color: #{lighten(#245ea4, 12%)} !important;
      color: var(--white);
    }
    &:disabled {
      color: var(--white);
      background-color: var(--disabled-color) !important;
    }
  }

  // - For Primary Button with border
  &.is-bordered {
    background-color: transparent !important;
    border: 1px solid var(--primary-color);
    color: var(--primary-color);
    &:hover,
    &:active,
    &:focus {
      border-color: #{lighten(#245ea4, 12%)};
      color: #{lighten(#245ea4, 12%)};
    }
    &:disabled {
      color: var(--disabled-color);
      border-color: var(--disabled-color) !important;
    }
  }

  // - For Button Link
  &.is-link {
    background-color: transparent;
    border: 1px solid transparent;
    color: var(--primary-color);
    font-weight: 500;
    padding: 9px 10px 8px !important;
    &:hover,
    &:focus,
    &:active {
      background-color: transparent;
      border: 1px solid transparent;
      color: #{lighten(#245ea4, 12%)};
    }
    &:disabled {
      color: var(--disabled-color) !important;
    }
    &.lg {
      padding: 0;
      font-size: 22px;
      line-height: 28px;
    }
    &.no-pad {
      padding: 0 4px !important;
    }
    &.underline {
      text-decoration: underline !important;
    }
  }

  // - For Button with icon
  &.with-icon {
    box-sizing: border-box;
    height: 32px;
    outline: none;
    .icon,
    .anticon {
      color: var(--white);
      display: inline-block;
      height: 18px;
      margin-right: 8px;
      width: 16px;
      font-size: 18px;
      line-height: 18px;
      vertical-align: middle;
      & + span {
        margin-left: 0 !important;
      }
    }

    &.colored {
      .icon,
      .anticon {
        color: var(--primary-color);
      }
    }
    &.transparent {
      background-color: transparent;
      .icon,
      .anticon {
        color: var(--primary-color);
      }
    }
  }
  &.is-fullWidth {
    text-align: center;
    width: 100%;
  }
  &.sm-bordered {
    background-color: transparent;
    border: 1px solid var(--primary-color);
    color: var(--primary-color);
    display: inline-block;
    padding: 2px 14px !important;
    font-size: 12px;
    text-decoration: none !important;
    position: relative;
    @include rounded-corners(20px);
    @include transition(all ease 0.3s);
    &:hover {
      background-color: var(--primary-color);
      color: var(--white);
    }
  }
  &.is-round {
    @include rounded-corners(50px);
  }
}

.settingButton {
  border: 0;
  cursor: pointer;
  height: 36px !important;
  padding: 0 !important;
  position: relative;
  width: 36px;
  @include rounded-corners(100px);
  &.type-user {
    background: url('../../../icons/user.png') center no-repeat !important;
  }
  &.type-notification {
    background: url('../../../icons/bell.png') center no-repeat !important;
    background-size: 24px !important;
    position: relative;

    .badge {
      background-color: var(--alert-fail);
      color: var(--white);
      display: inline-block;
      font-size: 13px;
      font-weight: 700;
      line-height: 10px;
      padding: 3px 5px 4px;
      position: absolute;
      min-height: 15px;
      min-width: 15px;
      right: 5px;
      top: -2px;
      z-index: 3;
      @include rounded-corners(100px);
    }
  }
}

@media all and (max-device-width: 768px) {
  .c-button {
    &.sm-bordered {
      font-size: 14px;
      line-height: normal;
      padding: 6px 18px !important;
    }
  }
}

/* -------------------------------------
      🍔 Components - Checkbox
  ------------------------------------- */
.c-checkbox {
  margin-bottom: 8px !important;
  .ant-checkbox {
    width: 18px;
    height: 18px;
    & + span {
      color: var(--fontColor-light);
    }
  }
  &:disabled {
    cursor: not-allowed;
  }
  .ant-checkbox-inner {
    width: 18px;
    height: 18px;
    background-color: var(--white);
    border: 1px solid #8c93ab;
    @include rounded-corners(3px);
  }
  .ant-checkbox-checked {
    .ant-checkbox-inner {
      background-color: var(--primary-color);
      &::after {
        top: calc(50% - 1px);
      }
    }
    & ~ span {
      color: var(--fontColor);
    }
  }
  .ant-checkbox-disabled {
    &.ant-checkbox-checked {
      .ant-checkbox-inner {
        background-color: #f5f5f5;
      }
      & ~ span {
        color: var(--fontColor);
      }
    }
  }
  .checkHolder {
    color: var(--fontColor-light);
    display: block;
    padding-right: 65px;
    font-size: 14px;
    line-height: 20px;
    text-transform: capitalize;
    position: relative;
    .hasPriority {
      position: relative;
      padding-left: 15px;
      &.is-low {
        &::before {
          background: var(--priority-low);
        }
      }
      &.is-major {
        &::before {
          background: var(--priority-major);
        }
      }
      &.is-critical {
        &::before {
          background: var(--priority-critical);
        }
      }
      &::before {
        background-color: var(--priority-critical);
        content: '';
        position: absolute;
        top: 5px;
        left: 0;
        height: 8px;
        width: 8px;
        @include rounded-corners(20px);
      }
    }
    .ins_count {
      background-color: #eef4fb;
      position: absolute;
      top: -1px;
      right: 10px;
      height: 22px;
      padding: 2px 12px;
      color: var(--primary-color);
      font-size: 13px;
      font-weight: 600;
      line-height: 18px;
      @include rounded-corners(100px);
    }
    .ins_text {
      display: block;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
}

/* -------------------------------------
      🍔 Components - Radio Button
  ------------------------------------- */
.c-radio {
  margin-bottom: 8px !important;
  .ant-radio {
    width: 18px;
    height: 18px;
    & + span {
      color: var(--fontColor-light);
    }
  }
  &:disabled {
    cursor: not-allowed;
  }
  .ant-radio-inner {
    width: 18px;
    height: 18px;
    background-color: var(--white);
    border: 1px solid #8c93ab;
    @include rounded-corners(50px);
  }
  .ant-radio-checked {
    .ant-radio-inner {
      background-color: transparent;
      &::after {
        width: 10px;
        height: 10px;
      }
    }
    & ~ span {
      color: var(--fontColor);
    }
  }
}

/* -------------------------------------
      🍔 Components - Textbox, Textarea
  ------------------------------------- */
.c-textbox {
  background-color: transparent !important;
  border: 1px solid var(--disabled-color) !important;
  color: var(--black);
  display: block;
  font-size: 14px;
  height: auto !important;
  line-height: 18px !important;
  letter-spacing: 0.1px;
  padding: 9px 12px 7px !important;
  outline-color: var(--primary-color);
  outline-width: thick;
  width: auto !important;
  @include rounded-corners(6px);
  @include box-shadow(none);
  @include transition(all ease 0.3s);

  // - For textbox hover, active states
  &:hover:not(:disabled),
  &:focus,
  &:active {
    border: 1px solid var(--primary-color) !important;
  }

  // - For textbox Placeholder styles
  &::placeholder {
    color: var(--placeholder-color) !important;
  }

  // - For textbox Disable states
  &:disabled {
    cursor: not-allowed;
    background-color: var(--page-bg-color) !important;
  }

  // - For textbox Error state
  &.has-error {
    border-color: var(--alert-fail) !important;
  }
}

.c-datePicker {
  display: block;
}

// - Wrapper for label and form field
.c-formField {
  display: block;
  position: relative;
  margin-bottom: 8px;
  .c-label {
    color: var(--fontColor-light);
    display: block;
    font-size: 12px;
    line-height: 18px;
    margin-bottom: 2px;
  }
}

/* -------------------------------------
      🍔 Components - Tags
  ------------------------------------- */
.c-tagList {
  display: block;
  &.inline {
    ul {
      display: inline-block;
      margin-right: 6px;
      padding-right: 6px;
      position: relative;
      &:not(:last-child) {
        &::after {
          position: absolute;
          content: '';
          top: 50%;
          margin-top: -9px;
          right: 0;
          height: 14px;
          width: 1px;
          background-color: var(--disabled-color);
        }
      }
    }
  }
  ul {
    display: block;
    margin: 0;
    padding: 0;
    list-style: none;
    li {
      display: inline-block;
      line-height: normal;
      vertical-align: middle;
      margin: 0 8px 4px 0;
      &.listTitle {
        color: var(--placeholder-color);
        font-size: 14px;
        line-height: 20px;
        &.block {
          display: block;
        }
        &.sm {
          color: var(--fontColor-extra-light);
          font-size: 9px;
          font-weight: 600;
          text-transform: uppercase;
        }
      }
    }
  }
}

.c-tag {
  background: rgba(80, 174, 85, 0.2);
  border: 0 !important;
  box-sizing: border-box;
  color: var(--fontColor-light) !important;
  display: inline-block;
  padding: 3px 12px !important;
  height: 24px !important;
  font-size: 12px !important;
  font-weight: 600;
  line-height: 18px !important;
  letter-spacing: 0.1px;
  margin-right: 0 !important;
  max-width: 200px;
  white-space: nowrap;
  overflow: hidden;
  position: relative;
  text-overflow: ellipsis;
  vertical-align: middle;
  @include rounded-corners(100px);

  &.is-closable {
    padding-right: 24px !important;

    .anticon-close {
      position: absolute !important;
      right: 6px !important;
      top: 6.5px;
    }
  }

  &.is-button {
    border: 0;
    cursor: pointer;
    outline-color: var(--fontColor-extra-light);
    outline-offset: 2px;
  }

  &.is-low {
    background: rgba(80, 174, 85, 0.2);
  }
  &.is-major {
    background: rgba(232, 145, 59, 0.2);
  }
  &.is-critical {
    background: rgba(234, 76, 70, 0.2);
  }
  &.is-disabled {
    background: var(--border-color);
    font-weight: 600;
  }
  &.is-camelCase {
    text-transform: capitalize;
  }
  &.to_do,
  &.done {
    background: #e2ffd4;
  }
  &.in_progress {
    background: #d4ecf7;
  }
  &.ignore,
  &.execution_failed {
    background: rgba(255, 237, 237, 1);
  }
}
.btn-disabled {
  button {
    border: 0;
  }
}

@media all and (max-device-width: 768px) {
  .c-tag {
    min-height: 26px !important;
    height: auto !important;
    padding: 4px 12px !important;
  }
}

/* -------------------------------------
      🍔 Components - Insight Recommendation Container
  ------------------------------------- */

.c-containerWrapper {
  background-color: var(--white);
  border: 1px solid #eee;
  display: flex;
  flex-direction: row;
  margin: 16px;
  padding: 16px;
  min-height: 300px;

  &.height-auto {
    min-height: 0;
  }

  &.sm {
    min-height: 150px;
  }

  .full-height {
    flex-grow: 1;
  }

  &.is-fullWidth {
    flex-direction: column;
  }
  &.divide-half {
    flex-direction: row;
    .section {
      flex-grow: 1;
    }
  }
  &.is-transparent {
    border: 0;
    background-color: transparent;
    padding: 0;
  }
  .container {
    &_sidebar {
      box-sizing: border-box;
      border-right: 1px solid var(--border-color);
      padding-right: 12px;
      min-width: 320px;
    }
    &_section {
      padding: 0 0 0 12px;
      border-left: 1px solid var(--border-color);
      margin-left: -1px;
      flex-grow: 1;
    }
  }

  &.for-mobile {
    display: block;
    margin: 8px;
    padding: 8px;
    .container {
      &_sidebar {
        border-right: 0;
        padding-right: 0;
        width: 100%;
      }
      &_section {
        padding: 0;
        border-left: 0;
        margin-left: 0;
      }
    }
  }
  &.hasDataError {
    .container {
      &_sidebar {
        display: none;
      }
      &_section {
        padding: 0;
        border-left: 0;
        margin-left: 0;
      }
    }
  }
}
.sidebarTitle {
  background-color: var(--page-bg-color);
  color: var(--primary-color);
  display: block;
  font-size: 14px;
  font-weight: 600;
  height: 40px;
  line-height: 22px;
  margin-bottom: 6px;
  padding: 8px 130px 8px 8px;
  position: relative;
  @include rounded-corners(4px);
  &.isFullWidth {
    padding-right: 8px;
  }
  .reset {
    background-color: var(--primary-color);
    border: 0;
    color: var(--white);
    cursor: pointer;
    font-size: 12px;
    font-weight: 500;
    line-height: 16px;
    height: 24px;
    padding: 1px 12px 3px;
    position: absolute;
    right: 10px;
    top: 8px;
    @include rounded-corners(100px);
  }
  &.clean {
    background-color: transparent;
    cursor: pointer;
    font-weight: normal;
    margin-bottom: 8px;
  }
}

/* -------------------------------------
      🍔 Components - Utilities
  ------------------------------------- */
.nopad {
  padding: 0 !important;
}
.color-light {
  color: var(--fontColor-light);
}
.color-dark {
  color: var(--fontColor);
}
.color-fail {
  color: var(--alert-fail) !important;
}
.color-success {
  color: var(--alert-success) !important;
}
.bold {
  font-weight: 700;
}
.no-bold {
  font-weight: 500;
}

.uppercase {
  text-transform: uppercase;
}
.no-border {
  border: 0 !important;
}
.no-pad {
  padding: 0 !important;
}
.underline {
  text-decoration: underline;
}
.inline-block {
  display: inline-block;
}
.hide {
  display: none;
}
.block {
  display: block !important;
}
.mb-0 {
  margin-bottom: 0 !important;
}
.mb-10 {
  margin-bottom: 10px !important;
}
@media all and (max-device-width: 768px) {
  .m-text-center {
    text-align: center !important;
  }
}

/* -------------------------------------
      🍔 Components - Heading
  ------------------------------------- */
.c-heading {
  color: var(--fontColor-light);
  display: block;
  position: relative;
  font-size: 14px;
  line-height: 22px;
  &.for-section {
    color: var(--fontColor);
    font-weight: 600;
    margin-bottom: 8px;
  }
  &.type-h3 {
    font-weight: 600;
  }

  &.is-truncated {
    padding-right: 10px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  &.colored {
    color: var(--primary-color);
  }
  &.inline {
    display: inline-block;
  }
  &.sm {
    font-size: 12px;
    line-height: 20px;
  }
  &.md {
    font-size: 16px;
    line-height: 22px;
  }
  &.lg {
    font-size: 24px;
    line-height: 36px;
  }
  &.xl {
    font-size: 32px;
    line-height: 44px;
  }
  &.color-light {
    color: var(--fontColor-light);
  }
  &.color-dark {
    color: var(--fontColor);
  }
  &.color-primary {
    color: var(--primary-color);
  }
  &.bold {
    font-weight: 700;
  }
}

/* -------------------------------------
      🍔 Components - Page Containers
  ------------------------------------- */
:root {
  --sidebar-width: 420px;
  @media all and (max-width: 1024px) {
    --sidebar-width: 300px;
  }
  @media all and (min-width: 1640px) {
    --sidebar-width: 500px;
  }
  @media all and (min-width: 1940px) {
    --sidebar-width: 640px;
  }
}
.c-pageBody {
  background-color: var(--white);
  position: relative;
  height: auto;
  min-height: 100vh;

  &.is-transparent {
    background-color: transparent;
  }
}
.c-contentWrapper {
  box-sizing: border-box;
  position: relative;

  .wrapper {
    &_left {
      position: relative;
      padding: 72px 0 0;
      width: calc(100% - var(--sidebar-width));

      &.is-fullWidth {
        width: 100%;
      }
      &.topPaddingCustom {
        padding-top: 120px;
      }

      &_container {
        padding: 24px;
      }
      &_footer {
        background-color: var(--white);
        color: var(--fontColor-dark);
        font-size: 1.15rem;
        line-height: 1.3rem;
        font-weight: 600;
        position: fixed;
        height: 48px;
        bottom: 0;
        left: 100px;
        padding: 16px;
        text-align: center;
        width: calc(100% - calc(var(--sidebar-width) + 100px));
        z-index: 99;
        @include box-shadow(-4px 0 20px 0 rgba(131, 134, 172, 0.17));
      }
    }
  }

  &.forMobile {
    .wrapper {
      &_left {
        padding: 60px 0 60px;
        width: 100%;

        &_container {
          padding: 12px;
        }
        &_footer {
          left: 0;
          height: 38px;
          padding: 12px;
          width: 100%;
        }
      }
    }
    .c-containerWrapper {
      margin: 10px;
    }
  }
}

.wrapper_right {
  .ant-drawer-content-wrapper {
    width: var(--sidebar-width) !important;
    @include box-shadow(-4px 0 20px 0 rgba(131, 134, 172, 0.17));
    .ant-drawer-wrapper-body {
      overflow-y: hidden;
    }
  }
  &.isMobile {
    .ant-drawer-content-wrapper {
      width: calc(100% - 30px) !important;
      max-width: 420px !important;
    }
  }
}

.c-dataContainer {
  display: block;
  border: 1px solid #eeeeee;
  background-color: var(--white);
  position: relative;
  min-height: 400px;
  height: auto;
  overflow: hidden;
  margin: 12px;
  padding: 24px;
  @include rounded-corners(4px);
  &.sm {
    padding: 24px 18px 24px 8px;
  }
}

@media all and (max-device-width: 768px) {
  .c-dataContainer {
    border: 0;
    margin: 16px 0 0;
    min-height: calc(100vh - 200px);
    padding: 14px;
    position: relative;
    &.sm {
      padding: 12px;
    }
  }
}

@media all and (min-width: 1940px) {
  .c-contentWrapper {
    .wrapper {
      &_left {
        &_container {
          max-width: 1500px;
          margin: 0 auto;
        }
      }
    }
  }
}

/* -------------------------------------
      🍔 Components - Chart and Graph
  ------------------------------------- */
// ----- Widget grid for business overview page
.type-BU-dashboard {
  .c-widgetGrid {
    display: grid;
    grid-template-columns: repeat(2, minmax(200px, 1fr));
    grid-gap: 12px;
    grid-auto-rows: auto;
    padding: 0;
    grid-auto-flow: dense;
    height: auto !important;
    .widgetGrid__item {
      min-height: 350px;
      position: inherit;
      touch-action: none;
      transform: inherit;
      width: auto;
      height: auto;

      &.fullWidth {
        grid-column: span 2;
      }
    }
    .fullWidth {
      grid-column: span 2;
    }
    .widgetGrid__container {
      position: inherit !important;
      touch-action: none !important;
      transform: inherit !important;
      width: auto !important;
      height: auto !important;
      &.fullWidth {
        grid-column: span 2;
      }
    }
  }
}

// ----- Widget grid for edit, create chart page
.type-createChart {
  .settingList {
    display: block;
  }

  .createChart {
    display: flex;
    flex-direction: row;
    gap: 16px;
    .chart {
      &_list {
        flex-grow: 1;
      }
      &_metrics {
        flex-basis: 300px;
      }
    }

    .metrics_input_wrapper {
      margin-bottom: 16px;
    }
  }

  .c-widgetGrid {
    .widgetGrid__container {
      &.fullWidth {
        grid-column: span 2;
      }
    }
    .fullWidth {
      grid-column: span 2;
    }
  }

  .isEditable {
    .c-chartWidget {
      border-color: #8c93ab;
      border-style: dashed;
    }
  }
}

.settingList {
  display: none;
  top: 15px;
  position: absolute;
  right: 16px;
  z-index: 10;
  ul {
    align-items: center;
    display: flex;
    flex-direction: row;
    gap: 8px;
    margin: 0;
    padding: 0;
    list-style: none;
    .listItem {
      min-height: 34px;
      min-width: 34px;

      .c-settingDropdown {
        border: 0;
        background-color: var(--page-bg-color);
        box-sizing: border-box;
        cursor: pointer;
        color: var(--fontColor-light);
        display: grid;
        place-items: center;
        padding: 3px;
        text-align: center;
        font-size: 18px;
        font-weight: 600;
        height: 32px;
        width: 32px;
      }
    }
  }
}

// ----- Chart container
.c-chartWidget {
  background-color: var(--white);
  border: 1px solid #e9ecf7;
  box-sizing: border-box;
  display: block;
  font-family: var(--primary-font);
  min-height: 450px;
  height: 100%;
  padding: 0;
  position: relative;
  @include rounded-corners(4px);
  @include box-shadow(0 4px 10px rgba(34, 41, 47, 0.05));
  &.height-auto {
    min-height: 300px;
    height: auto;
    .widget {
      &_body {
        min-height: 200px;
      }
    }
  }
  .widget {
    &_head {
      align-items: center;
      border-bottom: 1px solid #f9f9f9;
      display: flex;
      min-height: 66px;
      position: relative;
      padding: 16px 24px;
      &_info {
        box-sizing: border-box;
        flex-grow: 2;
        .chartTitle {
          display: block;
          color: var(--fontColor);
          font-size: 18px;
          font-weight: 600;
          line-height: 24px;
          .chartInfo {
            border: 0;
            background-color: transparent;
            color: var(--fontColor);
            cursor: pointer;
            display: inline-block;
            margin-left: 10px;
            padding: 2px;
            font-size: 14px;
            line-height: 14px;
          }
          &.lg {
            font-size: 22px;
            line-height: 30px;
          }
          &.sm {
            color: var(--fontColor-light);
            font-size: 14px;
            line-height: 20px;
          }
        }
      }
      &_settings {
        box-sizing: border-box;
        flex-grow: 1;
        ul {
          align-items: center;
          justify-content: flex-end;
          display: flex;
          flex-direction: row;
          gap: 6px;
          padding: 0;
          margin: 0;
          list-style-type: none;
          li {
            min-height: 30px;
            min-width: 30px;
          }
        }
      }
    }
    &_body {
      display: block;
      min-height: 490px;
      padding: 16px 24px;
      position: relative;

      &.sm {
        min-height: 420px;
      }
      &.at-center {
        display: grid;
        place-items: center;
      }
      &_container {
        display: block;
        position: relative;
        margin-bottom: 4px;
        .chartSubTitle {
          display: block;
          color: var(--fontColor);
          font-size: 11px;
          line-height: 14px;
          margin-bottom: 4px;
        }
      }
      &_chart {
        display: block;
        position: relative;
        min-height: 410px;
        background-color: var(--page-bg-color);
      }
    }
    &_footer {
      border-top: 1px solid var(--border-color);
      display: block;
      padding: 16px 24px;
    }
  }
}

.topWidgetRow {
  margin-bottom: 12px;
  .c-widgetGrid {
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    .c-chartWidget {
      min-height: 110px !important;
      position: relative;
      @include box-shadow(0 4px 10px rgba(34, 41, 47, 0.05));
      &::after {
        background: url('../../../icons/revenueTop.png') center no-repeat;
        background-size: 44px;
        position: absolute;
        bottom: 16px;
        right: 16px;
        height: 48px;
        width: 48px;
        content: '';
        opacity: 0.6;
      }
      &.visits {
        &::after {
          background: url('../../../icons/visits.png') center no-repeat;
          background-size: 44px;
        }
      }
      &.revenue {
        &::after {
          background: url('../../../icons/revenueTop.png') center no-repeat;
          background-size: 44px;
        }
      }
      &.rpv {
        &::after {
          background: url('../../../icons/rpv.png') center no-repeat;
          background-size: 44px;
        }
      }
      &.c-ratio {
        &::after {
          background: url('../../../icons/conversion.png') center no-repeat;
          background-size: 44px;
        }
      }

      .c-notifiContainer {
        display: flex;
        flex-direction: row;
        margin: 0;
        min-height: 40px;
        .ant-result-info {
          align-items: center;
          display: flex;
          flex-direction: row;
          gap: 12px;
          flex-grow: 1;
          .ant-result-icon {
            flex-basis: 60px;
            text-align: left;
            margin: 0;
            .notifi_icon {
              height: 52px;
              width: 52px;
            }
          }
          .ant-result-title {
            flex-grow: 1;
            text-align: left;
            .notifi_message {
              text-align: left;
            }
          }
        }
      }

      .time_granularity_wrapper,
      .chartInfo {
        display: none;
      }
      .widgetGrid__container {
        height: 340px !important;
      }
      .widget_head {
        border: 0;
        min-height: 52px;
        padding: 16px 24px 8px;
        &_info {
          .chartTitle {
            font: 600 16px/22px var(--primary-font);
          }
        }
      }
      .widget_body {
        min-height: 110px !important;
        padding: 0 24px;
        &_container {
          margin: 0;
        }
      }
    }
    .fusioncharts-container {
      display: none !important;
    }
  }
  .c-widgetStats {
    .stats {
      &_count {
        &_value {
          display: block;
          font-size: 32px;
          line-height: 36px;
          margin-bottom: 12px;
          span {
            font: 600 32px/36px var(--primary-font);
          }
        }
        &_diff {
          font-size: 18px;
          font-weight: 600;
          line-height: 22px;
          &.isCaretDown,
          &.isCaretUp {
            &::before {
              top: 8px;
              left: -4px;
              border-left: 8px solid transparent;
              border-right: 8px solid transparent;
            }
          }
          // &.isCaretDown {
          //   &::before {
          //     border-top: 10px solid var(--alert-fail) !important;
          //   }
          // }
          // &.isCaretUp {
          //   &::before {
          //     border-bottom: 10px solid var(--alert-success) !important;
          //   }
          // }
        }
      }
      &_info {
        display: none;
      }
    }
  }
}

.midWidgetRow {
  margin-bottom: 12px;
  .c-widgetGrid {
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  }
}

.c-widgetStats {
  display: inline-block;
  margin-right: 16px;
  padding-right: 16px;
  position: relative;
  vertical-align: middle;
  &:after {
    background-color: var(--border-color);
    content: '';
    position: absolute;
    top: 50%;
    right: 0;
    width: 1px;
    height: 32px;
    margin-top: -16px;
  }
  &:last-child {
    margin-right: 0;
    padding-right: 0;
    &:after {
      display: none;
    }
  }

  .stats {
    &_count {
      display: block;
      &_value {
        display: inline-block;
        position: relative;
        color: var(--primary-color);
        vertical-align: text-bottom;
        font: 600 24px/32px var(--primary-font);

        span {
          font: 600 24px/32px var(--primary-font);
        }
      }
    }
    &_info {
      display: block;
      position: relative;
      letter-spacing: 0.1px;
      color: var(--fontColor-extra-light);
      font: 500 14px/18px var(--primary-font);
      margin-top: 2px;
    }
  }

  &.showDifference {
    &.up {
      .stats {
        &_count {
          &_diff {
            color: var(--alert-success);
            &::before {
              border-left: 7px solid transparent;
              border-right: 7px solid transparent;
              border-bottom: 9px solid var(--alert-success);
            }
          }
        }
      }
    }
    &.down {
      .stats {
        &_count {
          &_diff {
            color: var(--alert-fail);
            &::before {
              border-left: 7px solid transparent;
              border-right: 7px solid transparent;
              border-top: 9px solid var(--alert-fail);
            }
          }
        }
      }
    }
  }
}

// --- Up Down Triangle for deviation
.stats_count_diff {
  display: inline-block;
  position: relative;
  color: var(--fontColor);
  padding-left: 20px;
  margin-left: 10px;
  font: 500 14px/18px var(--primary-font);

  &::before {
    content: '';
    position: absolute;
    top: 4px;
    left: 0;
    width: 0;
    height: 0;
  }

  &.isCaretUp {
    &::before {
      border-left: 7px solid transparent;
      border-right: 7px solid transparent;
      border-bottom: 9px solid var(--alert-success);
    }
  }
  &.isCaretDown {
    &::before {
      border-left: 7px solid transparent;
      border-right: 7px solid transparent;
      border-top: 9px solid var(--alert-fail);
    }
  }
  &.isColorGreen {
    color: var(--alert-success);
    &.isCaretUp {
      &::before {
        border-bottom: 9px solid var(--alert-success);
      }
    }
    &.isCaretDown {
      &::before {
        border-top: 9px solid var(--alert-success) !important;
      }
    }
  }
  &.isColorRed {
    color: var(--alert-fail);
    &.isCaretUp {
      &::before {
        border-bottom: 9px solid var(--alert-fail);
      }
    }
    &.isCaretDown {
      &::before {
        border-top: 9px solid var(--alert-fail);
      }
    }
  }
  &.sm {
    padding-left: 12px;
    margin-left: 6px;
    font: 600 12px/16px var(--primary-font);
    &::before {
      top: 5px;
    }

    &.isCaretUp {
      &::before {
        border-left: 4px solid transparent;
        border-right: 4px solid transparent;
        border-bottom: 6px solid var(--alert-success);
      }
    }
    &.isCaretDown {
      &::before {
        border-left: 4px solid transparent;
        border-right: 4px solid transparent;
        border-top: 6px solid var(--alert-fail);
      }
    }
    &.isColorGreen {
      &.isCaretUp {
        &::before {
          border-bottom: 6px solid var(--alert-success);
        }
      }
      &.isCaretDown {
        &::before {
          border-top: 6px solid var(--alert-success);
        }
      }
    }
    &.isColorRed {
      &.isCaretUp {
        &::before {
          border-bottom: 6px solid var(--alert-fail);
        }
      }
      &.isCaretDown {
        &::before {
          border-top: 6px solid var(--alert-fail);
        }
      }
    }
  }
}

.c-settingDropdown {
  border: 0;
  background-color: transparent;
  box-sizing: border-box;
  cursor: pointer;
  color: var(--fontColor-light);
  display: grid;
  font-size: 16px;
  font-weight: 600;
  height: 30px;
  outline: none !important;
  place-items: center;
  padding: 3px;
  text-align: center;
  width: 30px;
  margin: 0;
  @include transition(background-color ease 0.3s);
  @include rounded-corners(4px);
  .anticon {
    display: block;
  }
  &:hover {
    background-color: var(--page-bg-color);
  }
}

@media all and (max-device-width: 1160px) {
  // ----- Chart container
  .c-chartWidget {
    .widget {
      &_head {
        padding: 8px 16px !important;
        &_info {
          .chartTitle {
            font: 600 14px/20px var(--primary-font) !important;
            .chartInfo {
              font-size: 12px;
              line-height: 12px;
            }
          }
        }
      }
      &_body {
        padding: 8px 16px !important;
        &_container {
          .chartSubTitle {
            font-size: 11px;
            line-height: 14px;
          }
        }
      }
    }
  }

  .c-widgetStats {
    .stats {
      &_count {
        &_value {
          font: 600 20px/26px var(--primary-font) !important;
          span {
            font: 600 20px/26px var(--primary-font) !important;
          }
        }
        &_diff {
          font: 500 12px/16px var(--primary-font);
        }
      }
    }
  }
}

/* -------------------------------------
      🍔 Components - Custom Dropdown
  ------------------------------------- */
.c-customSelect {
  display: inline-block;
  height: 34px;
  vertical-align: middle;

  &.sm {
    .ant-select-selection {
      height: 34px !important;
      padding: 2px 0 !important;
    }
  }

  .customSelect_btn {
    background-color: var(--page-bg-color) !important;
    box-sizing: border-box;
    border: 0;
    display: block;
    height: 34px;
    padding: 5px 24px 5px 6px;
    position: relative;
    text-align: center;
    @include rounded-corners(4px);

    .chart {
      .anticon {
        color: var(--fontColor-light);
        display: inline-block;
        height: 20px;
        font-size: 18px;
        line-height: 18px;
        vertical-align: middle;
        width: 32px;
      }
    }

    .arrow {
      position: absolute;
      top: 50%;
      right: 7px;
      height: 10px;
      margin-top: -5px;
      width: 10px;

      color: var(--fontColor-light);
      font-size: 10px;
    }
  }
}

// --- custom dropdown with icon
.customSelect_overlay {
  background-color: var(--widget-bg-color);
  max-width: 240px;
  .no-icons {
    .customSelect_menu {
      font-weight: 500;
      padding: 8px 12px;
    }
  }
  .customSelect {
    &_menuWrapper {
      border: 1px solid var(--border-color-light);
      background-color: var(--widget-bg-color);
      padding: 8px 0;
      @include box-shadow(0 2px 3px var(--page-bg-color));
      @include rounded-corners(4px);
    }
    &_menu {
      background-color: transparent;
      box-sizing: border-box;
      border: 0;
      cursor: pointer;
      display: block;
      font-weight: 500;
      margin-bottom: 4px;
      min-height: 34px;
      padding: 8px 8px 8px 40px;
      position: relative;
      text-align: left;
      width: 100%;
      @include box-shadow(none);
      @include rounded-corners(0);
      @include transition(all ease 0.3s);
      &_icon {
        color: var(--fontColor-light);
        font-size: 18px;
        line-height: 18px;
        height: 20px;
        left: 8px;
        margin-top: -8px;
        position: absolute !important;
        top: 50%;
        width: 24px;
        @include transition(color ease 0.3s);
      }
      &_title {
        color: var(--fontColor);
        display: block;
        font-size: 13px;
        line-height: 20px;
        margin-left: 0;
        @include transition(color ease 0.3s);
      }

      &:hover,
      &.is-selected {
        background-color: var(--page-bg-color);
        .customSelect {
          &_menu {
            &_icon,
            &_title {
              color: var(--primary-color);
            }
          }
        }
      }
      &:disabled,
      &.is-disabled {
        background-color: transparent;
        color: var(--fontColor-light);
        cursor: not-allowed;
        .customSelect {
          &_menu {
            &_icon,
            &_title {
              color: var(--fontColor-light);
              opacity: 0.5;
            }
          }
        }
      }
    }
  }
}

.customPageBanner {
  align-items: center;
  border-bottom: 1px solid #cfebd6;
  background-color: #e6f2e9;
  display: flex;
  flex-direction: row;
  gap: 16px;
  margin: 0 auto;
  padding: 10px 16px;
  height: 52px;
  width: calc(100% - 100px);
  position: fixed;
  top: 68px;
  z-index: 99;
  .banner {
    &_image {
      height: 24px;
      width: 24px;
      img {
        height: 24px;
        width: 24px;
      }
    }
    &_title {
      color: var(--primary-color);
      flex-grow: 1;
      font-size: 1.4rem;
      font-weight: 600;
      line-height: 1.8rem;
    }
    &_action {
      flex-grow: 1;
      justify-content: flex-end;
      display: flex;
      flex-direction: row;
      gap: 16px;
    }
  }
}
