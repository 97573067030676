@import '../../assets/styles/scss/styleGuide/variables';

.c-errorPageContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  border: 1px solid #eeeeee;
  background-color: var(--white);
  position: relative;
  height: calc(100vh - 140px);
  top: 0;
  margin: 24px;
  padding: 24px;
  position: relative;
  z-index: 10;
  @include rounded-corners(4px);
  &::before {
    background-image: url('../../assets/images/analytics.svg');
    background-position: center center;
    background-size: 40% 40%;
    background-repeat: no-repeat;
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    -webkit-filter: blur(5px); /* Safari 6.0 - 9.0 */
    filter: blur(5px);
    content: '';
    opacity: 0.2;
  }
  .errorCode {
    border-bottom: 2px solid var(--primary-color);
    color: var(--primary-color);
    font-size: 200px;
    line-height: 220px;
    font-weight: 900;
    display: block;
    max-width: 500px;
    margin: 20px auto;
    position: relative;
    padding-bottom: 20px;
    z-index: 2;
    text-shadow: 1px 1px 1px var(--white), 3px 3px 5px var(--secondary-color);
  }
  .errorCode_emoji {
    display: block;
    font-size: 100px;
    line-height: 100px;
    height: 100px;
    width: 100px;
    margin-bottom: 36px;
  }
  .errorText {
    color: var(--primary-color);
    font-size: 40px;
    line-height: 50px;
    font-weight: 900;
    display: block;
    max-width: 500px;
    margin: 0 auto 24px;
    position: relative;
    text-transform: uppercase;
    z-index: 2;
  }
  .errorMessage {
    display: block;
    color: var(--font-color);
    text-align: center;
    font-size: 22px;
    line-height: 32px;
    font-weight: 600;
    width: 60%;
    margin: 0 auto;
    z-index: 2;
  }
}

@media all and (max-device-width: 768px) {
  .c-errorPageContainer {
    .errorCode {
      font-size: 100px;
      line-height: 120px;
    }
    .errorText {
      font-size: 20px;
      line-height: 30px;
    }
    .errorMessage {
      font-size: 16px;
      line-height: 22px;
      width: 100%;
    }
  }
}
